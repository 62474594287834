import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import _ from "lodash";

import { getAllCertifications, getOrgInfoSCA } from "../../utils/requests";
import { submitOrgDelete, submitOrgEdit } from "../../utils/submissions";
import Tooltip from "../tooltip";
import { useToken } from "../../TokenContext";
import { preValidateEditOrg } from "../../utils/preValidations";


function customEncode(str) {
	let encoded = btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1)));
	return encoded;
}

function formatDate(dateString) {
	const date = new Date(dateString);
	const year = date.getUTCFullYear();
	const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed
	const day = date.getUTCDate().toString().padStart(2, '0');

	return `${year}-${month}-${day}`;
}

const ViewOrEditOrg = ({ showDialog, setShowDialog }) => {
	const { currentToken } = useToken()
	const [editing, setEditing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isCopied, setIsCopied] = useState(false);

	const [errors, setErrors] = useState("");
	const [deleteValue, setDeleteValue] = useState("");
	const [deleteError, setDeleteError] = useState("");

	const navigate = useNavigate();
	const { orgNameParam } = useParams();
	const formatted_encode = customEncode(orgNameParam);
	const backendURL = process.env.REACT_APP_BACKEND_URL;

	const [infoCopy, setInfoCopy] = useState({});

	const [orgID, setOrgID] = useState("");
	const [orgName, setOrgName] = useState("");
	const [swiftComplyEmail, setSwiftComplyEmail] = useState("");
	const [emailList, setEmailList] = useState([]);
	const [emailFrequency, setEmailFrequency] = useState("");
	const [activeDate, setActiveDate] = useState("");
	const [numberOfCerts, setNumberOfCerts] = useState(1);
	const [banner, setBanner] = useState("");
	const [homePageBanner, setHomePageBanner] = useState("");
	const [internalNotes, setInternalNotes] = useState("");
	const [urlCode, setUrlCode] = useState("");
	const [allCertsArray, setAllCertsArray] = useState([]);
	const [associatedAgencies, setAssociatedAgencies] = useState(new Set());

	const addEmail = () => {
		const newEmailList = _.cloneDeep(emailList);
		newEmailList.push("");
		setEmailList(newEmailList);
	};

	const removeEmail = (idx) => {
		const newEmailList = _.cloneDeep(emailList);
		newEmailList.splice(idx, 1)
		setEmailList(newEmailList);
	};

	const handleEmailEntry = (idx, e) => {
		const newEmailList = _.cloneDeep(emailList);
		newEmailList[idx] = e.target.value;
		setEmailList(newEmailList);
	};

	const handleAssociateAgency = (agencyId) => {
		const newAssociatedAgencies = _.cloneDeep(associatedAgencies);
		newAssociatedAgencies.has(agencyId) ? newAssociatedAgencies.delete(agencyId) : newAssociatedAgencies.add(agencyId);
		setAssociatedAgencies(newAssociatedAgencies);
	}

	const cancelEdit = () => {
		setOrgID(infoCopy.org_id);
		setOrgName(infoCopy.org_name);
		setSwiftComplyEmail(infoCopy.swiftcomply_email);
		setEmailList(infoCopy.emails.filter(email => email));
		setEmailFrequency(infoCopy.emailReportFreq);
		setActiveDate(formatDate(infoCopy.allowedUntil));
		setNumberOfCerts(infoCopy.number_of_certs);
		setBanner(infoCopy.org_banner);
		setHomePageBanner(infoCopy.homePageBanner);
		setInternalNotes(infoCopy.internalNotes);

		if (backendURL.includes("127.")) {
			setUrlCode(`localhost:3000/form/${infoCopy.orgURL}`);
		} else {
			setUrlCode(`https://www.sc-tester.com/form/${infoCopy.orgURL}`);
		}

		const certAssociations = new Set();
		for (const agencyId of Object.keys(infoCopy.org_associated_certs)) certAssociations.add(agencyId);
		setAssociatedAgencies(certAssociations);

		setEditing(false);
	}

	async function copyTextToClipboard(text) {
		if ("clipboard" in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand("copy", true, text);
		}
	}

	const handleCopyClick = () => {
		// Asynchronously call copyTextToClipboard
		copyTextToClipboard(urlCode)
			.then(() => {
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 2500);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	async function handleOrgEdits(e) {
		e.preventDefault();
		setLoading(true);
		setErrors([]);

		const associatedAgenciesList = [];
		for (const item of associatedAgencies) associatedAgenciesList.push(item);

		const data = {
			orgInfo: {
				orgID: orgID,
				orgEndDate: activeDate,
				swiftcomplyEmail: swiftComplyEmail,
				orgEmails: emailList.map(email => ({ "email": email })),
				orgEmailFreq: emailFrequency,
				orgBanner: banner,
				numOfCerts: numberOfCerts,
				orgHomePageBanner: homePageBanner,
				orgInternalNotes: internalNotes,
				associatedAgencies: associatedAgenciesList,
			},
		};

        const preValErrors = preValidateEditOrg(data.orgInfo);
        if (preValErrors.length > 0) {
            setErrors(preValErrors);
            setLoading(false);
            document.getElementsByClassName("page")[0].scrollTo(0, 0);
            return;
        }

		const res = await submitOrgEdit(orgNameParam, data, currentToken);

		if (res.code === "SUCCESS") {
			setEditing(false);
			setShowDialog("edited");
			navigate(`/scadmin/vieworedit/${encodeURIComponent(orgName)}`);
		} else {
			res.message ? setErrors([res.message]) : setErrors(["API submission error."]);
			document.getElementsByClassName("page")[0].scrollTo(0, 0);
		}
		setLoading(false);
	}

	const handleOrgDelete = async () => {
		setLoading(true);
		setDeleteError("");

		if (deleteValue !== orgName) {
			setDeleteError("Org name incorrect. Please enter the exact org name.");
		} else {
			const payload = {
				"orgName": orgName,
				"orgID": orgID
			}

			const res = await submitOrgDelete(payload, currentToken);

			if (res.code === "SUCCESS") {
				navigate(`/scadmin/search`);
				setShowDialog("deleted");
			} else {
				setDeleteError(res.message);
			}
		}

		setLoading(false);
	}

	useEffect(() => {
		const getOrgInfo = async () => {

			const getOrgRes = await getOrgInfoSCA(formatted_encode, currentToken);
			const getCertsRes = await getAllCertifications(currentToken);

			if (getOrgRes.code === "VALID URL" && getCertsRes.code === "SUCCESS") {
				setInfoCopy(getOrgRes.message.orgInfo);

				setOrgID(getOrgRes.message.orgInfo.org_id);
				setOrgName(getOrgRes.message.orgInfo.org_name);
				setSwiftComplyEmail(getOrgRes.message.orgInfo.swiftcomply_email);
				// Response includes emails as an array with null values for empty emails.
				// Might just be a problem with seed data. New data seems to come over as empty strings.
				setEmailList(getOrgRes.message.orgInfo.emails.filter(email => email));
				setEmailFrequency(getOrgRes.message.orgInfo.emailReportFreq);
				setActiveDate(formatDate(getOrgRes.message.orgInfo.allowedUntil));
				setNumberOfCerts(getOrgRes.message.orgInfo.number_of_certs);
				setBanner(getOrgRes.message.orgInfo.org_banner);
				setHomePageBanner(getOrgRes.message.orgInfo.homePageBanner);
				setInternalNotes(getOrgRes.message.orgInfo.internalNotes);

				if (backendURL.includes("127.")) {
					setUrlCode(`localhost:3000/form/${getOrgRes.message.orgInfo.orgURL}`);
				} else {
					setUrlCode(`https://www.sc-tester.com/form/${getOrgRes.message.orgInfo.orgURL}`);
				}

				setAllCertsArray(Object.entries(getCertsRes.message).map((pair) => ({ agencyId: pair[0], agencyName: pair[1].agency })).sort((a, b) => {
					if (a.agencyName.toLowerCase() < b.agencyName.toLowerCase()) {
						return -1;
					}
					if (a.agencyName.toLowerCase() > b.agencyName.toLowerCase()) {
						return 1;
					}
					return 0;
				}))

				const certAssociations = new Set();
				for (const agencyId of Object.keys(getOrgRes.message.orgInfo.org_associated_certs)) certAssociations.add(agencyId);
				setAssociatedAgencies(certAssociations);

			} else {
				setErrors(["API request error."]);
			}

		}

		getOrgInfo()
	}, [formatted_encode, backendURL])

	return (
		<>
			{!showDialog && (
				<form method="post" className="form">
					<div className="section">
						<div className="sub-heading">
							{editing ? "Edit Organization" : "View Organization"}
						</div>

						<div className="group">
							{errors.length > 0 && (
								<div className="row">
									<div className="field">
										<ul className="errors">
											{errors.map((error, errorIdx) => (<li key={errorIdx}>{error}</li>))}
										</ul>
									</div>
								</div>
							)}

							<div className="row">
								<label className="field">
									<div className="label">
										Organization Name*
										<Tooltip
											text={
												"The name of the city/organization. This name will show on the tester facing form, and cannot be changed after creation."
											}
										/>
									</div>
									<input required={true} value={orgName} disabled={true} />
								</label>
							</div>

							<div className="row list-email">
								<label className="field">
									<div className="label">
										SwiftComply Email
										<Tooltip
											text={
												"The primary internal email to receive sign up notifications."
											}
										/>
									</div>
									<input
										value={swiftComplyEmail}
										onChange={(e) => setSwiftComplyEmail(e.target.value)}
										disabled={!editing}
										className="item"
									/>
								</label>
							</div>

							<div className="row list-email">
								{emailList.map((email, idx) => (
									<label className="field" key={idx}>
										<div className="label">
											Email #{idx + 1}
											<Tooltip
												text={
													"An additional email (can be internal or external) to receive sign up notifications."
												}
											/>
										</div>
										<div>
											<input
												name="email"
												value={email}
												onChange={(e) => handleEmailEntry(idx, e)}
												disabled={!editing}
												className="item"
											/>
											{idx > 0 && (
												<button
													type="button"
													className="button"
													onClick={() => removeEmail(idx)}
													disabled={!editing}
												>
													Remove This Email
												</button>
											)}
										</div>
									</label>
								))}
								{emailList.length < 3 && (
									<button
										type="button"
										className="button"
										onClick={addEmail}
										disabled={!editing}
									>
										Add Another Email
									</button>
								)}
							</div>

							<div className="row">
								<label className="field">
									<div className="label">
										Email Report Frequency
										<Tooltip
											text={
												"How often sign up notifications should be sent out to the emails above. \
                            Daily emails will be sent Monday - Friday at 8am EST. \
                            Weekly emails will be sent once a week on Monday at 8am EST. \
                            Twice a week emails will be sent on Monday and Thursday at 8am EST. \
                            Monthly emails will be sent every 1st of the Month at 8am EST."
											}
										/>
									</div>
									<select
										onChange={(e) => setEmailFrequency(e.target.value)}
										value={emailFrequency}
										disabled={!editing}
									>
										<option value={""}>Select a frequency</option>
										<option>Daily (Business Days)</option>
										<option>Weekly</option>
										<option>Twice a Week (MTh)</option>
										<option>Monthly</option>
									</select>
								</label>

								<label className="field">
									<div className="label">
										Allowed Access Until*
										<Tooltip
											text={
												"The expiration date for public access to this form."
											}
										/>
									</div>
									<input
										type="date"
										required={true}
										value={activeDate}
										onChange={(e) => setActiveDate(e.target.value)}
										disabled={!editing}
									/>
								</label>

								<label className="field">
									<div className="label">
										Required Certifications*
										<Tooltip
											text={
												"The number of certifications a tester is REQUIRED to submit. For example, if this organization accepts AWWA, ABPA, and ASSE certifications, but a tester only needs one of those to test, enter 1 here."
											}
										/>
									</div>
									<input
										type="number"
										required={true}
										value={numberOfCerts}
										onChange={(e) => setNumberOfCerts(e.target.value)}
										disabled={!editing}
										min={0}
									/>
								</label>
							</div>

							<div className="table-container-row">
								<table cellPadding="0" cellSpacing="0">
									<thead>
										<tr>
											<th colSpan="2">
												<div className="label">
													Enabled Certification Agencies
													<Tooltip
														text={
															"Check each certification agency that this organization recognizes. ABPA, ASSE, and AWWA are checked by default."
														}
													/>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										{allCertsArray &&
											allCertsArray.map(({ agencyId, agencyName }) => {
												return (
													<tr key={agencyId}>
														<td style={{ paddingRight: "0" }}>
															<input
																type="checkbox"
																checked={associatedAgencies.has(agencyId)}
																disabled={!editing}
																onChange={() =>
																	handleAssociateAgency(agencyId)
																}
															/>
														</td>

														<td>{agencyName}</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>

							<div className="row">
								<label className="field">
									<div className="label">
										Home Banner Message
										<Tooltip
											text={
												"Additional details that will be displayed in the Registration Info section of this organization's home page."
											}
										/>
									</div>
									<textarea
										value={homePageBanner}
										onChange={(e) => setHomePageBanner(e.target.value)}
										disabled={!editing}
									/>
								</label>
							</div>

							<div className="row">
								<label className="field">
									<div className="label">
										Form Banner Message
										<Tooltip
											text={
												"Additional details that will be displayed on the first page of this organization's registration form."
											}
										/>
									</div>
									<textarea
										value={banner}
										onChange={(e) => setBanner(e.target.value)}
										disabled={!editing}
									/>
								</label>
							</div>

							<div className="row">
								<label className="field">
									<div className="label">
										Internal Notes
										<Tooltip
											text={
												"Additional details for internal reference that will be NOT be publicly displayed."
											}
										/>
									</div>
									<textarea
										value={internalNotes}
										onChange={(e) => setInternalNotes(e.target.value)}
										disabled={!editing}
									/>
								</label>
							</div>

							<div className="row">
								<label className="field">
									<div className="label">
										Organization URL
										<Tooltip
											text={
												"A link to this organization's live registration site. This link is generated based on the organization's name, and is unique to that organization."
											}
										/>
									</div>
									<input
										type="textarea"
										value={urlCode}
										disabled={true}
										readOnly
									/>
								</label>
								<button
									type="button"
									className="button field-copy"
									onClick={() => handleCopyClick()}
									disabled={!isCopied ? false : true}
								>
									<span>{isCopied ? "Copied!" : "Copy"}</span>
								</button>
							</div>

							<div className="row-buttons">
								{editing && (
									<>
										<button
											type="submit"
											onClick={handleOrgEdits}
											className="button green"
											disabled={!editing || loading}
										>
											{loading ? "Loading..." : "Submit Edits"}
										</button>
										<button
											type="button"
											className="button red"
											onClick={() => setShowDialog("delete")}
											disabled={!editing || loading}
										>
											{loading ? "Loading..." : "Delete Org"}
										</button>
										<button
											type="button"
											onClick={() => cancelEdit()}
											className="button"
										>
											Cancel
										</button>
									</>
								)}

								{!editing && (
									<button
										type="button"
										onClick={() => setEditing(true)}
										className="button"
									>
										Edit Info
									</button>
								)}
							</div>
						</div>
					</div>
				</form>
			)}

			{(showDialog === "created" || showDialog === "edited") && (
				<div className="form">
					<div className="section">
						<div className="sub-heading">
							Organization successfully{" "}
							{showDialog === "created" ? "created" : "edited"}!
						</div>
						<div className="group">
							<div className="row">
								<label className="field">
									Organization URL
									<input
										type="textarea"
										value={urlCode}
										disabled={true}
										readOnly
									/>
								</label>
								<button
									type="button"
									className="button field-copy"
									onClick={() => handleCopyClick()}
									disabled={!isCopied ? false : true}
								>
									<span>{isCopied ? "Copied!" : "Copy"}</span>
								</button>
							</div>
							<div className="row">
								<button
									className="button green"
									onClick={() => setShowDialog("")}
								>
									{showDialog === "created" ? "Continue" : "Back"}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{showDialog === "delete" && (
				<div className="form">
					<div className="section">
						<div className="sub-heading">Delete Organization</div>
						<div className="group">
							{deleteError !== "" && (
								<div className="row">
									<div className="field">
										<ul className="errors">
											<li>{deleteError}</li>
										</ul>
									</div>
								</div>
							)}

							<div className="row">
								<label className="field">
									<div>
										Are you sure you want to delete <strong>{orgName}</strong>
										? Please enter "<strong>{orgName}</strong>" to continue.
									</div>
									<input
										value={deleteValue}
										onChange={(e) => setDeleteValue(e.target.value)}
									/>
								</label>
							</div>

							<div className="row-buttons">
								<button className="button" onClick={() => setShowDialog("")}>
									Cancel
								</button>
								<button
									className="button red"
									onClick={() => handleOrgDelete()}
								>
									{loading ? "Loading..." : "Delete"}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}


export default ViewOrEditOrg;
