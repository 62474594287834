import React from "react";

const CompanyMatches = ({ companyMatchList, chooseCompany }) => {
    return (
        <div className="section">
            <div className="group">
                
                <div className="row">
                    <p className="first-p">
                        We found one or more companies in our database similar to the company you entered.
                        If one of the options below seems to be a correct match, please select it. If none of
                        these options match, you may skip selection and continue with the company you entered.
                        <strong> Only skip if you cannot find a similar match.</strong>
                    </p>
                </div>

                <div className="table-container">
                    <table cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Company Name</th>
                                <th>Address</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Zip Code</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {companyMatchList.map((info, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{info.companyName}</td>
                                        <td>{info.companyAddress1} {info.companyAddress2}</td>
                                        <td>{info.companyCity}</td>
                                        <td>{info.companyState}</td>
                                        <td>{info.companyZip}</td>
                                        <td><button type="submit" className="button" onClick={(e) => chooseCompany(e, info.companyID, idx)}>Select</button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="row">
                    <p className="last-p">
                        Contact SwiftComply support if your company is listed here, but the information is incorrect.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default CompanyMatches;