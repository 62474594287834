import { createContext, useState, useContext } from "react";

const TokenContext = createContext(null);

export const TokenProvider = ({ children, token }) => {
  const [currentToken, setCurrentToken] = useState(token);

  const updateToken = (newToken) => {
    setCurrentToken(newToken);
  };

  return (
    <TokenContext.Provider value={{ currentToken, updateToken }}>
      {children}
    </TokenContext.Provider>
  );
};


export const useToken = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error("useToken must be used within a TokenProvider");
  }
  return context;
};