import React from "react";

import { getErrorMessages } from "../utils/errors";

const Summary = ({ companyInfo, combinedUsers, testKitInfo, userErrors, orgCerts }) => {
    const errorMessages = getErrorMessages(userErrors);

    return (
        <>

            <div className="section">
                <div className="group">

                    <div className="row">
                        <div>
                            <p className="first-p">
                                Below is a summary of all your entered information. Please review it to ensure accuracy. If you would like to change any of the information, please navigate back to the appropriate entry page. If everything looks accurate, you may proceed with a final submit.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section">
                <div className="sub-heading-summary">Company Details</div>
                <div className="group">

                    <div className="row">
                        <label className="field">
                            Company Name
                            <input
                                name="companyName"
                                value={companyInfo[0].companyName}
                                disabled={true}
                            />
                        </label>
                        <label className="field">
                            Company Phone
                            <input
                                name="companyPhone"
                                value={companyInfo[0].companyPhone}
                                disabled={true}
                            />
                        </label>
                    </div>

                    <div className="row">
                        <label className="field">
                            Company Address
                            <input
                                name="companyAddress1"
                                value={companyInfo[0].companyAddress1}
                                disabled={true}
                            />
                        </label>
                    </div>
                    <div className="row">
                        <label className="field">
                            Company Address Line 2
                            <input
                                name="companyAddress2"
                                value={companyInfo[0].companyAddress2}
                                disabled={true}
                            />
                        </label>
                    </div>

                    <div className="row">
                        <label className="field">
                            City
                            <input
                                name="companyCity"
                                value={companyInfo[0].companyCity}
                                disabled={true}
                            />
                        </label>
                        <label className="field">
                            State
                            <input
                                name="companyState"
                                value={companyInfo[0].companyState}
                                disabled={true}
                            />
                        </label>
                        <label className="field">
                            Zip Code
                            <input
                                name="companyZip"
                                value={companyInfo[0].companyZip}
                                disabled={true}
                            />
                        </label>
                    </div>

                </div>
            </div>

            {combinedUsers.map((userInfo, userIdx) => {
                return (
                    <div className="section" key={userIdx}>
                        <div className="sub-heading-summary">User #{userIdx + 1} [{userInfo.userType === "admin" ? "Admin" : "Tester"}]</div>
                        <div className="group">

                            {errorMessages[userIdx]?.length > 0 && (
                                <div className="row">
                                    <div className="field">
                                        <ul className="errors">
                                            {errorMessages[0].map((error, idx) => <li key={idx}>{error}</li>)}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <label className="field">
                                    First Name
                                    <input
                                        name="userFirstName"
                                        value={userInfo.userFirstName}
                                        disabled={true}
                                    />
                                </label>
                                <label className="field">
                                    Last Name
                                    <input
                                        name="userLastName"
                                        value={userInfo.userLastName}
                                        disabled={true}
                                    />
                                </label>
                            </div>

                            <div className="row">
                                <label className="field">
                                    Email
                                    <input
                                        name="userEmail"
                                        value={userInfo.userEmail}
                                        disabled={true}
                                    />
                                </label>
                                <label className="field">
                                    Phone
                                    <input
                                        name="userPhone"
                                        value={userInfo.userPhone}
                                        disabled={true}
                                    />
                                </label>
                            </div>

                            {userInfo.userType === "tester" && (userInfo.userCerts.map((cert, certIdx) => {
                                return (
                                    <div className="subgroup" key={certIdx}>
                                        <div className="divider" />
                                        <div className="row">
                                            <div className="field">
                                                <strong>Certification #{certIdx + 1}</strong>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <label className="field">
                                                Number
                                                <input
                                                    name="certNum"
                                                    value={cert.certNum}
                                                    disabled={true}
                                                />
                                            </label>
                                            <label className="field">
                                                Expiration
                                                <input
                                                    name="certExp"
                                                    value={cert.certExp}
                                                    disabled={true}
                                                />
                                            </label>
                                        </div>

                                        <div className="row">
                                            <label className="field">
                                                Agency
                                                <input
                                                    name="certAgency"
                                                    value={orgCerts[cert.certAgency].agency}
                                                    disabled={true}
                                                />
                                            </label>
                                            <label className="field">
                                                Type
                                                <input
                                                    name="certType"
                                                    value={orgCerts[cert.certAgency].associatedTypes[cert.certType]}
                                                    disabled={true}
                                                />
                                            </label>
                                            <label className="field">
                                                Document
                                                <input
                                                    name="certDoc"
                                                    value={cert.certDoc.name}
                                                    disabled={true}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                )
                            }))}

                        </div>
                    </div>
                )
            })}

            {testKitInfo[0].kitSerial && testKitInfo.map((testKit, idx) => {
                return (
                    <div className="section" key={idx}>
                        <div className="sub-heading-summary">Test Kit #{idx + 1}</div>
                        <div className="group">

                            <div className="row">
                                <label className="field">
                                    Serial Number
                                    <input
                                        name="kitSerial"
                                        value={testKit.kitSerial}
                                        disabled={true}
                                    />
                                </label>
                                <label className="field">
                                    Manufacturer
                                    <input
                                        name="kitMfr"
                                        value={testKit.kitMfr}
                                        disabled={true}
                                    />
                                </label>
                                <label className="field">
                                    Model
                                    <input
                                        name="kitModel"
                                        value={testKit.kitModel}
                                        disabled={true}
                                    />
                                </label>
                            </div>

                            <div className="row">
                                <label className="field">
                                    Calibration Date
                                    <input
                                        name="kitCalibDate"
                                        value={testKit.kitCalibDate}
                                        disabled={true}
                                    />
                                </label>
                                <label className="field">
                                    Calibration Document
                                    <input
                                        name="kitCalibDoc"
                                        value={testKit.kitCalibDoc.name}
                                        disabled={true}
                                    />
                                </label>
                            </div>

                        </div>
                    </div>
                )
            })}

        </>
    )
}

export default Summary;