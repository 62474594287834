import _ from "lodash";
import { preValidateAdmins, preValidateCerts, preValidateCompany, preValidateTestKits, preValidateTesters } from "./preValidations";
import { combineUsers } from "./combineUsers";
import APIService from "../APIService";

// Validate user type through API request.
export async function submitUserType(setLoading, setInfoErrors, formUserType) {
    // Indicate to the user that their submit request is in progress.
    setLoading(true);

    // Reset the info errors. (Since there are no fields assigned
    // to the errors this request will return, this is important.)
    setInfoErrors({});

    const data = { "userType": formUserType };

    try {
        const response = await APIService.validateUserType(data);

        if (response.code === "USER TYPE ERROR") {
            setInfoErrors({
                "0": {
                    "User Error": ["Please Select Admin or Tester."]
                }
            })
        }

        setLoading(false);

        return response.code;
    } catch (error) {
        console.log(error);
        return "FETCH FAILURE";
    }
}

// Handle the submittal of company data. Return errors if validation errors.
export async function submitCompany(setCompanyMatchList, companyInfo, setCompanyInfo, setCompanyErrors, setLoading, setChosenCompany) {
    // Indicate to the user that their submit request is in progress.
    setLoading(true);

    // Reset the company matches state, in case the user
    // has navigated back to the company input page after
    // receiving matches from their first submit.
    setCompanyMatchList([]);

    // Also reset the chosen company for the reason above.
    setChosenCompany({});

    // Also reset the company ID for the same reason as above.
    const newCompanyInfo = JSON.parse(JSON.stringify(companyInfo));
    newCompanyInfo[0].companyID = "";
    setCompanyInfo(newCompanyInfo);

    // Reset company errors.
    setCompanyErrors({});

    // Perform frontend validations.
    const errors = preValidateCompany(companyInfo);
    if (Object.keys(errors).length > 0) {
        setCompanyErrors(errors);
        setLoading(false);
        return "COMPANY ERRORS";
    }

    // API request will validate entered company input.
    const data = { "companyInfo": companyInfo };

    try {
        const response = await APIService.validateCompany(data);

        // Handle the API response.
        if (response.code === "COMPANY ERRORS") {
            setCompanyErrors(response.message.company_errors);
        } else if (response.code === "Found Possible Matches") {
            setCompanyMatchList(response.message.possible_matches);
        } else if (response.code === "Found Exact Match") {
            // If we found an exact match, just update the ID for the company
            // info the user input. The backend will utilize the ID.
            const newCompanyInfo = JSON.parse(JSON.stringify(companyInfo));
            newCompanyInfo[0].companyID = response.message.match_found;
            setCompanyInfo(newCompanyInfo);
        }

        // Remove the loading indicator after the API returns a response.
        setLoading(false);

        return response.code;
    } catch (error) {
        console.log(error);
        return "FETCH FAILURE";
    }
}

export async function submitAdminInfo(setLoading, adminInfo, testerInfo, setAdminErrors) {
    setAdminErrors({});
    setLoading(true);

    // Perform frontend validations.
    const errors = preValidateAdmins(adminInfo, testerInfo);
    if (Object.keys(errors).length > 0) {
        setAdminErrors(errors);
        setLoading(false);
        return "ADMIN ERRORS";
    }

    const payload = {
        adminInfo: adminInfo
    }

    try {
        const response = await APIService.validateAdminInfo(payload);

        // Set errors state if errors returned.
        if (response.code === "ADMIN ERRORS") {
            setAdminErrors(response.message.adminErrors);
        }

        setLoading(false);

        return response.code;
    } catch (error) {
        console.log(error);
        return "FETCH FAILURE";
    }
}

export async function submitTesterInfo(setLoading, testerInfo, adminInfo, setTesterErrors, setCertErrors) {
    setTesterErrors({});
    setCertErrors({});
    setLoading(true);

    // Perform frontend validations.
    const testerPreErrors = preValidateTesters(testerInfo, adminInfo);
    const certPreErrors = preValidateCerts(testerInfo);

    if ((Object.keys(testerPreErrors).length > 0) || (Object.keys(certPreErrors).length > 0)) {
        if (Object.keys(testerPreErrors).length > 0) setTesterErrors(testerPreErrors);
        if (Object.keys(certPreErrors).length > 0) setCertErrors(certPreErrors);

        setLoading(false);
        return "TESTER ERRORS";
    }

    const payload = {
        testerInfo: testerInfo
    }

    try {
        const response = await APIService.validateTesterInfo(payload);

        // Set errors state if errors returned.
        if (response.code === "TESTER ERRORS") {
            if (response.message.testerErrors) {
                setTesterErrors(response.message.testerErrors);
            }
            if (response.message.certErrors) {
                setCertErrors(response.message.certErrors);
            }
        }

        setLoading(false);

        return response.code;
    } catch (error) {
        console.log(error);
        return "FETCH FAILURE";
    }
}

export async function submitTestKitInfo(setLoading, testKitInfo, setTestKitErrors) {
    setTestKitErrors({});
    setLoading(true);

    // Perform frontend validations.
    const errors = preValidateTestKits(testKitInfo);
    if (Object.keys(errors).length > 0) {
        setTestKitErrors(errors);
        setLoading(false);
        return "TEST KIT ERRORS";
    }

    const payload = {
        testKitInfo: testKitInfo
    }

    try {
        const response = await APIService.validateTestKitInfo(payload);

        // Set errors state if errors returned.
        if (response.code === "TEST KIT ERRORS") {
            setTestKitErrors(response.message.testKitErrors);
        }

        setLoading(false);

        return response.code;
    } catch (error) {
        console.log(error);
        return "FETCH FAILURE";
    }
}

// Handle form submittal, send form data to the API, and reset form values.
export async function submitFinalInfo(hashValue, companyInfo, adminInfo, testerInfo, testKitInfo, setUserErrors, setLoading) {
    setUserErrors({});
    setLoading(true);


    // Backend accepts admins and testers as one type, so they must be combined.
    const userInfo = combineUsers(adminInfo, testerInfo);

    // Ensure we don't submit an empty test kit to the backend.
    let finalKitInfo = testKitInfo[0].kitSerial ? _.cloneDeep(testKitInfo) : [];

    // Remove files from testKitInfo.
    for (const testKit of finalKitInfo) {
        delete testKit.kitCalibDoc;
    }

    // Remove files from userInfo
    for (const user of userInfo) {
        if (user.userType === "tester") {
            for (const cert of user.userCerts) {
                delete cert.certDoc;
            }
        }
    }

    const payload = { "hashValue": hashValue, "companyInfo": companyInfo, "userInfo": userInfo, "testKitInfo": finalKitInfo };

    try {
        const response = await APIService.SubmitForm(payload);

        // Set errors state if errors returned.
        if (response.code === "FINAL ERRORS") {
            if (response.message.userErrors) {
                setUserErrors(response.message.userErrors);
            }
        }

        setLoading(false);

        return response.code;
    } catch (error) {
        console.log(error);
        return "FETCH FAILURE";
    }
}

export async function submitNewAgency(agency, token) {
    const payload = { "agency": agency };

    try {
        const res = await APIService.createAgency(payload, token);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export async function submitDeleteAgency(agency, token) {
    const payload = { "agency": agency };

    try {
        const res = await APIService.deleteAgency(payload, token);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export async function submitNewType(type, token) {
    const payload = { "type": type };

    try {
        const res = await APIService.createType(payload, token);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export async function submitDeleteType(type, token) {
    const payload = { "type": type };

    try {
        const res = await APIService.deleteType(payload, token);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export async function submitTypeAssociation(agencyId, typeId, enabled, token) {

    const payload = {
        "agencyID": agencyId,
        "typeID": typeId,
        "enabled": enabled
    };

    try {
        const res = await APIService.createTypeAssociation(payload, token);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export async function submitOrgEdit(orgNameParam, data, token) {

    try {
        const res = await APIService.editOrg(orgNameParam, data, token);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export async function submitOrgDelete(payload, token) {

    try {
        const res = await APIService.deleteOrg(payload, token);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export async function submitOrgCreate(payload, token) {

    try {
        const res = await APIService.createOrg(payload, token);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

// Function being removed as APIService needs to be called directly from component.
export async function submitGenerateReport(payload) {

    try {
        // const res = await APIService.getOrgReport(payload);

        // Simulate response time.
        const sleep = ms => new Promise(
            resolve => setTimeout(resolve, ms));
        await sleep(1000);

        const res = {
            "message": "Report generated.",
            "code": "SUCCESS"
        }

        // const res = {
        //     "message": ["Could not generate report."],
        //     "code": "GET REPORT ERROR"
        // }

        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export async function submitUserEnrollStatus(orgId, userId, newStatus, token) {

    try {
        // Simulate response time.
        const sleep = ms => new Promise(
            resolve => setTimeout(resolve, ms));
        await sleep(1000);

        let res = {
            "message": true,
            "code": "SUCCESS"
        }

        // res = {
        //     "message": "Could not change status.",
        //     "code": "STATUS CHANGE ERROR"
        // }

        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export async function submitEditUser(userId, userInfo, token) {

    try {
        // Simulate response time.
        const sleep = ms => new Promise(
            resolve => setTimeout(resolve, ms));
        await sleep(1000);

        let res = {};

        res = {
            "message": {
                "userType": "tester",
                "userFirstName": "Brian",
                "userLastName": "Joseph",
                "userEmail": "bjoseph@mail.co",
                "userPhone": "544-012-3456",
                "userOrgs": {
                    "7": {
                        "name": "City of Atlanta, GA",
                        "enrolled": true
                    },
                    "9": {
                        "name": "City of Madison, TN",
                        "enrolled": false
                    }
                },
                "userServiceProviders": {
                    "34": "Eastfield Backflow & Fire",
                    "29": "Brian Joe's Plumbing"
                },
                "userCerts": {
                    "233": {
                        "certNum": "3462346",
                        "certExp": "2025-01-01",
                        "certAgency": "AWWA",
                        "certType": "Cross Connection Certified",
                        "certDocLink": "https://fake.link/g3g3-3g333vf-3t3b-33efd"
                    },
                    "288": {
                        "certNum": "854656",
                        "certExp": "2025-12-07",
                        "certAgency": "ABPA",
                        "certType": "Backflow Testing Certified",
                        "certDocLink": "https://fake.link/2grw-2hbtj-2r2r-wgwg"
                    }
                }
            },
            "code": "SUCCESS"
        }

        // res = {
        //     "message": "Edit user failed.",
        //     "code": "EDIT USER ERROR"
        // }

        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export async function submitEnrollAllUsersStatus(orgId, token) {

    try {
        // Simulate response time.
        const sleep = ms => new Promise(
            resolve => setTimeout(resolve, ms));
        await sleep(1000);

        let res = {};

        res = {
            "message": "Success",
            "code": "SUCCESS"
        }

        // res = {
        //     "message": "Enroll all users failed.",
        //     "code": "ENROLL ALL USERS ERROR"
        // }

        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}