import React from "react";

import { getErrorMessages } from "../utils/errors";

const Info = ({ formUserType, handleUserTypeChange, infoErrors, orgInfo }) => {
    const errorMessages = getErrorMessages(infoErrors);

    const renderFormBanner = () => {
      if (!orgInfo.org_banner) {
        return null
      }

      // Regular expression to find URLs
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      // Split the banner text by URLs
      const parts = orgInfo.org_banner.split(urlRegex);

      const formatText = (text) => {
            // Replace CITY, ST after "again for" with bold text - not being used, using -b
            // text = text.replace(/(again for\s)([^.]+)/, (match, p1, p2) => `${p1}<strong>${p2}</strong>`);

            // this will make anything in -b and -b as bold
            text = text.replace(/-b\s*(.*?)\s*-b/g, "<strong>$1</strong>");

            return text;
      }

      return (
        <div>
          {parts.map((part, index) => {
            if (part.match(urlRegex)) {
              // If part is a URL, render it as a clickable link
              return (
                <a
                  key={index}
                  href={part}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {part}
                </a>
              );
            } else {
              // Otherwise, render the regular text with preserved line breaks
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: formatText(part).replace(/\n/g, "<br>"),
                  }}
                />
              );
            }
          })}
        </div>
      );
    };

    return (
      <div className="section">
        <div className="group">
          {errorMessages[0]?.length > 0 && (
            <div className="row">
              <div className="field">
                <ul className="errors">
                  {errorMessages[0].map((error, idx) => (
                    <li key={idx}>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="row">
            <div>
              <p className="first-p">
                <strong>
                  Welcome to SwiftComply tester registration for{" "}
                  {orgInfo.org_name}.
                </strong>
              </p>
              <p>
                Please complete this multi-step form to be able to submit tests
                for <strong>{orgInfo.org_name}</strong> on their SwiftComply
                production site. Each user being registered is required to have
                a unique email address. If you have already completed
                registration with SwiftComply for a different organization,
                please continue with this registration to be able to test with{" "}
                <strong>{orgInfo.org_name}</strong>.
              </p>
              <p>
                {renderFormBanner()}
              </p>
            </div>
          </div>

          <div className="row">
            <div>
              <p>Are you registering as a tester or an admin?</p>
              <div>
                <input
                  name="userType"
                  id="tester"
                  type="radio"
                  checked={formUserType === "tester"}
                  onChange={() => handleUserTypeChange("tester")}
                />
                <label htmlFor="tester">
                  Tester
                </label>
              </div>

              <div>
                <input
                  name="userType"
                  id="admin"
                  type="radio"
                  checked={formUserType === "admin"}
                  onChange={() => handleUserTypeChange("admin")}
                />
                <label htmlFor="admin">
                  Admin (I will be submitting tests for others)*
                </label>
              </div>
              <p className="annotation">*Admin accounts are not allowed to submit tests for others in the state of Texas.</p>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Info;