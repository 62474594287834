import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";
import { Outlet } from "react-router-dom";
import LoginForm from "../SCAdmin_Auth/LoginContainer"; // Import the LoginForm component
import "../../styles/CSS/sca-base.css";
import { useToken } from "../../TokenContext";
import APIService from "../../APIService";
// import jwt_decode from "jsonwebtoken";

export default function Layout({ setShowDialog }) {
  // const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { currentToken, updateToken } = useToken();

  useEffect(() => {
    const storedToken = sessionStorage.getItem("authToken");
    if (storedToken) {
      const fetchData = async () => {
        try {
          const response = await APIService.verifyAuth(storedToken);
          if (response.code === "VERIFIED") {
            updateToken(storedToken);
            setIsLoggedIn(true);
          } else if (response.code == "EXPIRED") {
            sessionStorage.removeItem("authToken");
            setIsLoggedIn(false);
          } else {
            console.error("Verification failed:", response.message);
            // Handle failed verification (e.g., remove token, redirect to login)
            sessionStorage.removeItem("authToken");
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error("Error fetching verification:", error);
          // Handle errors (e.g., display error message)
        }
      };
      fetchData();
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLoginSuccess = (token) => {
    updateToken(token);
    setIsLoggedIn(true); // Update the state when login is successful
  };

  const handleLogoutSuccess = () => {
    updateToken(null);
    setIsLoggedIn(false); // Update the state when logout is successful
  };

  return (
    <div>
      {isLoggedIn ? (
        <div className="sca-layout">
          <NavBar onLogoutSuccess={handleLogoutSuccess} />
          <SideBar setShowDialog={setShowDialog} />
          <div className="page">
            <Outlet />
          </div>
        </div>
      ) : (
        <LoginForm onLoginSuccess={handleLoginSuccess} /> // Passing the function as a prop
      )}
    </div>
  );
}
