import React from "react";


const Submitted = () => {
    return (
        <div className="section">
            <div className="group">
                <div className="row">
                    <div>
                        <p className="first-p">
                            <strong>
                                Thank you for your submission!
                            </strong>
                        </p>
                        <p className="last-p">
                            Your data is being processed. Once processing is complete, you will receive a welcome package via email. While you wait, please check out the <a target="_blank" rel="noopener noreferrer" href="https://knowledge.swiftcomply.com/">SwiftComply Knowledge Base</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Submitted;