import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";

import { useToken } from "../../TokenContext";
import { getOrgs, getUsers } from "../../utils/requests";
import SearchSelect from "../searchSelect";
import { submitEnrollAllUsersStatus, submitUserEnrollStatus } from "../../utils/submissions";
import { preValidateRequestUsers } from "../../utils/preValidations";
import Tooltip from "../tooltip";

const Users = () => {
    const navigate = useNavigate();
    const { currentToken } = useToken();

    const [searchParams, setSearchParams] = useSearchParams();
    const paramPage = parseInt(searchParams.get("page")) || 1;
    const paramOrgId = searchParams.get("org") || "";
    const paramName = searchParams.get("name") || "";

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);

    const [users, setUsers] = useState({});
    const [sortedUsers, setSortedUsers] = useState([]);
    const [finalPage, setFinalPage] = useState(1);

    const [organizations, setOrganizations] = useState({});
    const [orgQueryCheck, setOrgQueryCheck] = useState(false);

    const [queryFilter, setQueryFilter] = useState("All Organizations");
    const [queryName, setQueryName] = useState("");
    const [queryOrgId, setQueryOrgId] = useState("");

    const sortUsers = (rawUsers) => {
        return Object.entries(rawUsers).map(([userId, userInfo]) => ({ userId: userId, userFirstName: userInfo.userFirstName })).sort((a, b) => {
            if (a.userFirstName.toLowerCase() < b.userFirstName.toLowerCase()) {
                return -1;
            }
            if (a.userFirstName.toLowerCase() > b.userFirstName.toLowerCase()) {
                return 1;
            }
            return 0;
        })
    }

    const handleEnroll = async (userId, currentStatus) => {
        setErrors([]);
        setLoading(true);

        const res = await submitUserEnrollStatus(paramOrgId, userId, !currentStatus, currentToken);
        if (res.code === "SUCCESS") {
            const newUsers = _.cloneDeep(users);
            newUsers[userId].userEnrolled = res.message;
            setUsers(newUsers);
        } else {
            console.log(res.code);
            setErrors([res.message]);
            document.getElementsByClassName("page")[0].scrollTo(0, 0);
        }

        setLoading(false);
    }

    const handleEnrollAll = async () => {
        setErrors([]);
        setLoading(true);

        const res = await submitEnrollAllUsersStatus(paramOrgId, currentToken);
        if (res.code === "SUCCESS") {
            const newUsers = _.cloneDeep(users);
            for (let userId of Object.keys(newUsers)) newUsers[userId].userEnrolled = true;
            setUsers(newUsers);
        } else {
            console.log(res.code);
            setErrors([res.message]);
            document.getElementsByClassName("page")[0].scrollTo(0, 0);
        }

        setLoading(false);
    }

    const handleSelectUser = async (e, userId) => {
        if (e.target.type === "checkbox") return;

        navigate(`/scadmin/users/${userId}`);
    }

    const handleQuery = async () => {
        setErrors([]);

        if (queryFilter === "An Organization" && !orgQueryCheck) {
            setErrors(["Please select a valid organization."]);
            document.getElementsByClassName("page")[0].scrollTo(0, 0);
            return;
        }

        const preValErrors = preValidateRequestUsers(queryFilter, queryOrgId, queryName);
        if (preValErrors.length > 0) {
            setErrors(preValErrors);
            document.getElementsByClassName("page")[0].scrollTo(0, 0);
            return;
        }

        let newSearchParams = { "page": 1 };
        if (queryOrgId !== "") newSearchParams["org"] = queryOrgId;
        if (queryName !== "") newSearchParams["name"] = queryName;
        setSearchParams(newSearchParams);
    }

    const handlePageChange = async (change) => {
        let newSearchParams = {};

        if (change === "previous") newSearchParams["page"] = paramPage - 1;
        if (change === "next") newSearchParams["page"] = paramPage + 1;

        if (paramOrgId !== "") newSearchParams["org"] = paramOrgId;
        if (paramName !== "") newSearchParams["name"] = paramName;

        setSearchParams(newSearchParams);
    }


    useEffect(() => {
        setErrors([]);

        const requestOrgInfo = async () => {
            const res = await getOrgs(currentToken);
            if (res.code === "SUCCESS") {
                setOrganizations(res.message);
            } else {
                console.log(res.code);
                setErrors([res.message]);
                document.getElementsByClassName("page")[0].scrollTo(0, 0);
            }
        };

        const requestUsers = async () => {
            setLoading(true);

            const res = await getUsers(paramOrgId, paramName, paramPage, currentToken);
            if (res.code === "SUCCESS") {
                setUsers(res.message.usersInfo);
                setSortedUsers(sortUsers(res.message.usersInfo));
                setFinalPage(res.message.finalPage);
            } else {
                console.log(res.code);
                setErrors([res.message]);
                setUsers({});
                setSortedUsers([]);
                setFinalPage(1);
                document.getElementsByClassName("page")[0].scrollTo(0, 0);
            }

            setLoading(false);
        }

        requestOrgInfo();
        requestUsers();
    }, [paramOrgId, paramName, paramPage, currentToken]);

    useEffect(() => {
        document.getElementsByClassName("page")[0].scrollTo(0, 0);
    }, [paramOrgId, paramName]);

    return (
        <div className="form">
            <div className="section">
                <div className="sub-heading">Find Users</div>
                <div className="group">

                    {errors.length > 0 && (
                        <div className="row">
                            <div className="field">
                                <ul className="errors">
                                    {errors.map((error, errorIdx) => (<li key={errorIdx}>{error}</li>))}
                                </ul>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <label className="field">
                            Find Users For
                            <div className="toggle">
                                <button
                                    className={
                                        queryFilter === "All Organizations"
                                            ? "option left selected"
                                            : "option left"
                                    }
                                    onClick={() => {
                                        setQueryFilter("All Organizations");
                                        setQueryOrgId("");
                                    }}
                                >
                                    All Organizations
                                </button>
                                <button
                                    className={
                                        queryFilter === "An Organization"
                                            ? "option right selected"
                                            : "option right"
                                    }
                                    onClick={() => setQueryFilter("An Organization")}
                                >
                                    An Organization
                                </button>
                            </div>
                        </label>

                        <label className="field">
                            Name
                            <input
                                name="queryName"
                                value={queryName}
                                onChange={(e) => setQueryName(e.target.value)}
                            />
                        </label>
                    </div>

                    {queryFilter === "An Organization" && (
                        <div className="row">
                            <label className="field">
                                Organization
                                <SearchSelect
                                    options={organizations}
                                    setFunction={setQueryOrgId}
                                    setCheckFunction={setOrgQueryCheck}
                                />
                            </label>
                        </div>
                    )}

                    <div className="row">
                        <div className="field-button">
                            <button
                                className="button green"
                                onClick={handleQuery}
                                disabled={loading}
                            >
                                Search
                            </button>
                        </div>
                    </div>


                    {paramOrgId !== "" && (
                        <>
                            <div className="divider-hard" />
                            <div className="row">
                                <label className="field">
                                    <div className="label">
                                        Selected Organization
                                        <Tooltip
                                            text={
                                                "Enroll all users for the organization named below. This will enroll ALL users for that organization, not just the users displayed in the list below."
                                            }
                                        />
                                    </div>
                                    <input value={organizations[paramOrgId]} disabled={true} />
                                </label>

                                <button className="button field-copy" disabled={loading} onClick={handleEnrollAll}>
                                    Enroll All Users
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {Object.entries(users).length > 0 && (
                <div className="table-container-full">
                    <table cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                {paramOrgId !== "" && !loading && (<th>Enrolled</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedUsers && sortedUsers.map(({ userId, userFirstName }) => {
                                return (
                                    <tr key={userId} className="clickable" onClick={(e) => handleSelectUser(e, userId)}>
                                        <td>{userFirstName}</td>
                                        <td>{users[userId].userLastName}</td>
                                        <td>{users[userId].userPhone}</td>
                                        <td>{users[userId].userEmail}</td>
                                        {paramOrgId !== "" && !loading && (
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={users[userId].userEnrolled}
                                                    disabled={loading}
                                                    onChange={(e) => handleEnroll(userId, e.target.checked)}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {!loading && (
                <div>
                    Page {paramPage} of {finalPage}
                </div>
            )}


            {!loading && !(paramPage > finalPage) && (
                <div className="buttons">
                    {paramPage > 1 && (
                        <button
                            type="button"
                            className="button"
                            onClick={() => handlePageChange("previous")}
                        >Previous Page</button>
                    )}
                    {paramPage < finalPage && (
                        <button
                            type="button"
                            className="button"
                            onClick={() => handlePageChange("next")}
                        >Next Page</button>
                    )}
                </div>
            )}

            {loading && (
                <div className="buttons">
                    <div>Loading...</div>
                </div>
            )}

        </div>
    )
}

export default Users;