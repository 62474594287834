import React, { useState } from "react";
import _ from "lodash";

import APIService from "../APIService";
import { getErrorMessages } from "../utils/errors";
import Tooltip from "./tooltip";

const TestKits = ({ testKitInfo, setTestKitInfo, handleTestKitEntry, removeTestKit, testKitErrors, setTestKitErrors, orgHash }) => {
    const [uploading, setUploading] = useState(-1);
    const errorMessages = getErrorMessages(testKitErrors);

    const handleDocUpload = async (testKitIdx) => {
        setUploading(testKitIdx);

        // Grab the file from the input.
        const docData = testKitInfo[testKitIdx].kitCalibDoc;

        // Holding place for file errors:
        const fileErrors = [];

        // Perform PreValidations:
        if (docData.size > 10000000) {
            // File size measured in bytes.
            fileErrors.push("File must be under 10mb.");
        }

        if (!["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(docData.type)) {
            fileErrors.push("File type not supported.");
        }

        // If PreValidations produced errors, set the error state, and do not attempt file upload.
        if (fileErrors.length > 0) {
            const newTestKitErrors = _.cloneDeep(testKitErrors);
            newTestKitErrors[testKitIdx] ? newTestKitErrors[testKitIdx].kitCalibDoc = fileErrors : newTestKitErrors[testKitIdx] = { "kitCalibDoc": fileErrors };
            setTestKitErrors(newTestKitErrors);

            setUploading(-1);
            return;
        }

        // Attempt file upload.
        const res = await APIService.uploadDoc(docData, orgHash);

        if (res.code === "SUCCESS") {
            // Deep copy testKitInfo and set the doc link for this specific test kit.
            const newTestKitInfo = _.cloneDeep(testKitInfo);
            newTestKitInfo[testKitIdx].kitCalibDocLink = res.message.url;
            setTestKitInfo(newTestKitInfo);
        } else {
            // Set the errors for this test kit to reflect a file upload problem.
            const newTestKitErrors = _.cloneDeep(testKitErrors);
            const uploadErrorMsg = ["There was a problem uploading this file."];
            newTestKitErrors[testKitIdx] ? newTestKitErrors[testKitIdx].kitCalibDoc = uploadErrorMsg : newTestKitErrors[testKitIdx] = { "kitCalibDoc": uploadErrorMsg };
            setTestKitErrors(newTestKitErrors);
        }

        setUploading(-1);
    }

    return (
        <>
            {testKitInfo.map((testKit, idx) => {
                return (
                  <div className="section" key={idx}>
                    <div className="sub-heading">
                      <div className="label">
                        Test Kit #{idx + 1}
                        <Tooltip
                          text={
                            "To submit test reports through SwiftComply, a current and calibrated test kit is required. You can click skip if you don't have a test kit to register."
                          }
                        />
                      </div>
                    </div>
                    <div className="group">
                      {errorMessages[idx]?.length > 0 && (
                        <div className="row">
                          <div className="field">
                            <ul className="errors">
                              {errorMessages[idx].map((error, errorIdx) => (
                                <li key={errorIdx}>{error}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <label
                          className={
                            testKitErrors[idx]?.kitSerial
                              ? "field error-highlight"
                              : "field"
                          }
                        >
                          Serial Number*
                          <input
                            name="kitSerial"
                            value={testKit.kitSerial}
                            onChange={(event) => handleTestKitEntry(idx, event)}
                          />
                        </label>
                        <label
                          className={
                            testKitErrors[idx]?.kitMfr
                              ? "field error-highlight"
                              : "field"
                          }
                        >
                          Manufacturer*
                          <input
                            name="kitMfr"
                            value={testKit.kitMfr}
                            onChange={(event) => handleTestKitEntry(idx, event)}
                          />
                        </label>
                        <label
                          className={
                            testKitErrors[idx]?.kitModel
                              ? "field error-highlight"
                              : "field"
                          }
                        >
                          Model*
                          <input
                            name="kitModel"
                            value={testKit.kitModel}
                            onChange={(event) => handleTestKitEntry(idx, event)}
                          />
                        </label>
                      </div>

                      <div className="row">
                        <label
                          className={
                            testKitErrors[idx]?.kitCalibDate
                              ? "field error-highlight"
                              : "field"
                          }
                        >
                          Last Calibration Date*
                          <input
                            type="date"
                            name="kitCalibDate"
                            value={testKit.kitCalibDate}
                            onChange={(event) => handleTestKitEntry(idx, event)}
                          />
                        </label>
                      </div>

                      <div className="row">
                        <div
                          className={
                            testKitErrors[idx]?.kitCalibDoc
                              ? "field error-highlight"
                              : "field"
                          }
                        >
                          <div className="label">
                            Calibration Document*
                            <Tooltip
                              text={
                                "Please attach a copy of your calibration document. After you choose a file, click the upload button to submit the file."
                              }
                            />
                          </div>
                          <div className="file-input">
                            <div className="row">
                              <label className="button-file">
                                Choose a file...
                                <input
                                  type="file"
                                  accept="image/jpg, image/jpeg, image/png, application/pdf"
                                  name="kitCalibDoc"
                                  onChange={(event) =>
                                    handleTestKitEntry(idx, event)
                                  }
                                />
                              </label>
                              <div
                                className="print-hidden"
                                style={{ fontSize: 1 + "rem" }}
                              >
                                .jpg, .jpeg, .pdf, .png (under 10mb)
                              </div>
                            </div>
                            <div className="row">
                              <button
                                type="button"
                                className={
                                  testKitInfo[idx].kitCalibDocLink === "" &&
                                  testKitInfo[idx].kitCalibDoc
                                    ? "button green"
                                    : "button"
                                }
                                onClick={() => handleDocUpload(idx)}
                                disabled={
                                  uploading === idx
                                    ? true
                                    : testKitInfo[idx].kitCalibDocLink === "" &&
                                      testKitInfo[idx].kitCalibDoc
                                    ? false
                                    : true
                                }
                              >
                                {uploading === idx
                                  ? "Uploading..."
                                  : testKitInfo[idx].kitCalibDocLink === ""
                                  ? "Upload"
                                  : "Uploaded"}
                              </button>
                              {testKitInfo[idx].kitCalibDoc && (
                                <div>{testKitInfo[idx].kitCalibDoc.name}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {idx > 0 && (
                          <button
                            type="button"
                            onClick={() => removeTestKit(idx)}
                            className="button"
                          >
                            Remove Test Kit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
            })}
        </>
    )
}

export default TestKits;