import React from "react";

const SCAdmin = () => {

    return (
        <div className="form">
            <div className="section">
                <div className="sub-heading">Home</div>
                <div className="group">
                    <div className="row">
                        <div>
                            <h2 className="first-p">Welcome!</h2>

                            <p>
                                Use the sidebar to navigate between different sections of the dashboard. Before performing any actions, ensure you know what you are doing. Changes made in this dashboard affect tester registration forms in real time. See below for a short description of each section:
                            </p>

                            <ul>
                                <li><strong>Create Organization:</strong>
                                    <ul>
                                        <li>Create a new registration form for an organization.</li>
                                    </ul>
                                </li>
                                <li><strong>Search/Edit Organization:</strong>
                                    <ul>
                                        <li>Find an existing registration form, and view or edit the form setup.</li>
                                    </ul>
                                </li>
                                <li><strong>Certifications:</strong>
                                    <ul>
                                        <li>Manage all approved certification agencies and types. </li>
                                        <li>If a certification agency or type was recently approved, it must be added here first in order to show up as an option when creating or editing a form.</li>
                                    </ul>
                                </li>
                                <li><strong>Active Organizations:</strong>
                                    <ul>
                                        <li>View all active registration forms.</li>
                                    </ul>
                                </li>
                                <li><strong>In-Active Organizations:</strong>
                                    <ul>
                                        <li>View all in-active registration forms.</li>
                                    </ul>
                                </li>
                                <li><strong>Reports:</strong>
                                    <ul>
                                        <li>Generate reports for an existing registration form.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SCAdmin