import React, { useState } from "react";
import { Link } from "react-router-dom";
import APIService from "../../APIService";
import { useToken } from "../../TokenContext";


const EditSearchOrganizations = ({ showDialog, setShowDialog }) => {
    const {currentToken} = useToken()
    const [orgName, setOrgName] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState(null);

    const handleOrgEntry = async (e) => {
        setOrgName(e.target.value);

        try {
            const response = await APIService.searchOrg(e.target.value, currentToken);
            const results = await response.json();
            setSearchResults(results);

            if (!results.length) {
                setError('No organizations found.');
            } else {
                setError(null);
            }
        } catch (err) {
            setError('Error searching for organizations.');
        }
    };


    return (
        <>
            {!showDialog && (
                <form className="form">
                    <div className="section">
                        <div className="sub-heading">Search/Edit Organization</div>
                        <div className="group">

                            <div className="row">
                                <label className="field">
                                    Please enter the name of the organization you would like to edit.
                                    <input
                                        required={true}
                                        value={orgName}
                                        onChange={(e) => handleOrgEntry(e)}
                                    />
                                </label>
                            </div>
                            <div className="row list">
                                {searchResults.map(org => (
                                    <Link key={org.id} to={`/scadmin/vieworedit/${encodeURIComponent(org.name)}`} className="button item">
                                        {org.name}
                                    </Link>
                                ))}
                            </div>
                            {error && <div className="error">{error}</div>}

                        </div>
                    </div>
                </form>
            )}

            {showDialog === "deleted" && (
                <div className="form">
                    <div className="section">
                        <div className="sub-heading">
                            Delete Organization
                        </div>
                        <div className="group">

                            <div className="row">
                                <div>
                                    <p>Organization successfully deleted.</p>
                                </div>
                            </div>

                            <div className="row-buttons">
                                <button className="button" onClick={() => setShowDialog("")}>Continue</button>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default EditSearchOrganizations;
