import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { submitEditUser, submitUserEnrollStatus } from "../../utils/submissions";
import { preValidateEditUser } from "../../utils/preValidations";
import { getUserInfo } from "../../utils/requests";
import { useToken } from "../../TokenContext";

const User = () => {
    const { currentToken } = useToken();
    const navigate = useNavigate();

    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);

    const { userId } = useParams();
    const [userInfo, setUserInfo] = useState({});
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userType, setUserType] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");

    const handleEnroll = async (orgId, userId, currentStatus) => {
        setLoading(true);

        const res = await submitUserEnrollStatus(orgId, userId, !currentStatus, currentToken)
        if (res.code === "SUCCESS") {
            const newUserInfo = _.cloneDeep(userInfo);
            newUserInfo.userOrgs[orgId].enrolled = res.message;
            setUserInfo(newUserInfo);
        } else {
            console.log(res.code);
            setErrors([res.message]);
            document.getElementsByClassName("page")[0].scrollTo(0, 0);
        }

        setLoading(false);
    }

    const handleCancelEdit = () => {
        setUserFirstName(userInfo.userFirstName);
        setUserLastName(userInfo.userLastName);
        setUserType(userInfo.userType);
        setUserEmail(userInfo.userEmail);
        setUserPhone(userInfo.userPhone);
        setErrors([]);
        setEditing(false);
    }

    const handleSaveEdits = async () => {
        setErrors([]);
        setLoading(true);

        const newUserInfo = {
            "userType": userType,
            "userFirstName": userFirstName,
            "userLastName": userLastName,
            "userEmail": userEmail,
            "userPhone": userPhone,
        };

        const preValErrors = preValidateEditUser(newUserInfo);
        if (preValErrors.length > 0) {
            setErrors(preValErrors);
            document.getElementsByClassName("page")[0].scrollTo(0, 0);
            setLoading(false);
            return;
        }

        const res = await submitEditUser(userId, newUserInfo, currentToken);
        if (res.code === "SUCCESS") {
            setUserInfo(res.message);
            setUserFirstName(res.message.userFirstName);
            setUserLastName(res.message.userLastName);
            setUserEmail(res.message.userEmail);
            setUserPhone(res.message.userPhone);
            setUserType(res.message.userType);
            setEditing(false);
        } else {
            console.log(res.code);
            setErrors([res.message]);
            document.getElementsByClassName("page")[0].scrollTo(0, 0);
        }

        setLoading(false);
    }

    useEffect(() => {
        const requestUserInfo = async () => {
            const res = await getUserInfo(userId, currentToken);
            if (res.code === "SUCCESS") {
                setUserInfo(res.message);
                setUserFirstName(res.message.userFirstName);
                setUserLastName(res.message.userLastName);
                setUserEmail(res.message.userEmail);
                setUserPhone(res.message.userPhone);
                setUserType(res.message.userType);
            } else {
                console.log(res.code);
                setErrors([res.message]);
                document.getElementsByClassName("page")[0].scrollTo(0, 0);
            }
        }

        requestUserInfo();
    }, [userId, currentToken]);

    return (
        <div className="form">
            <div className="section">
                <div className="sub-heading">User Info</div>
                <div className="group">

                    {errors.length > 0 && (
                        <div className="row">
                            <div className="field">
                                <ul className="errors">
                                    {errors.map((error, errorIdx) => (<li key={errorIdx}>{error}</li>))}
                                </ul>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <label className="field">
                            First Name*
                            <input
                                required={true}
                                value={userFirstName}
                                onChange={(e) => setUserFirstName(e.target.value)}
                                disabled={!editing || loading}
                            />
                        </label>
                        <label className="field">
                            Last Name*
                            <input
                                required={true}
                                value={userLastName}
                                onChange={(e) => setUserLastName(e.target.value)}
                                disabled={!editing || loading}
                            />
                        </label>
                        <label className="field">
                            User Type*
                            <select
                                required={true}
                                value={userType}
                                onChange={(e) => setUserType(e.target.value)}
                                disabled={!editing || loading}
                            >
                                <option value="tester">Tester</option>
                                <option value="admin">Admin</option>
                            </select>
                        </label>
                    </div>

                    <div className="row">
                        <label className="field">
                            Email*
                            <input
                                required={true}
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                disabled={!editing || loading}
                            />
                        </label>
                        <label className="field">
                            Phone
                            <input
                                required={true}
                                value={userPhone}
                                onChange={(e) => setUserPhone(e.target.value)}
                                disabled={!editing || loading}
                            />
                        </label>
                    </div>

                    <div className="row">
                        <div className="field">
                            <div className="label">
                                <strong>Associated Organizations</strong>
                            </div>
                        </div>
                    </div>
                    <div className="table-container-row" style={{ height: "fit-content", marginTop: "0rem" }}>
                        <table cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Organization Name</th>
                                    <th>Enrolled?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userInfo.userOrgs && Object.entries(userInfo.userOrgs).map(([orgId, orgData]) => {
                                    return (
                                        <tr id={orgId}>
                                            <td>{orgData.name}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={orgData.enrolled}
                                                    disabled={loading}
                                                    onChange={(e) => handleEnroll(orgId, userId, e.target.checked)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="row">
                        <div className="field">
                            <div className="label">
                                <strong>Associated Service Providers</strong>
                            </div>
                        </div>
                    </div>
                    <div className="table-container-row" style={{ height: "fit-content", marginTop: "0rem" }}>
                        <table cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Service Provider Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userInfo.userServiceProviders && Object.entries(userInfo.userServiceProviders).map(([serviceProviderId, serviceProviderName]) => {
                                    return (
                                        <tr id={serviceProviderId}>
                                            <td>{serviceProviderName}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="row">
                        <div className="field">
                            <div className="label">
                                <strong>Certifications</strong>
                            </div>
                        </div>
                    </div>
                    <div className="table-container-row" style={{ height: "fit-content", marginTop: "0rem" }}>
                        <table cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Expiration</th>
                                    <th>Agency</th>
                                    <th>Type</th>
                                    <th>Document</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userInfo.userCerts && Object.entries(userInfo.userCerts).map(([certId, certInfo]) => {
                                    return (
                                        <tr id={certId}>
                                            <td>{certInfo.certNum}</td>
                                            <td>{certInfo.certExp}</td>
                                            <td>{certInfo.certAgency}</td>
                                            <td>{certInfo.certType}</td>
                                            <td><a href={certInfo.certDocLink} target="_blank" rel="noopener noreferrer">Download</a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="divider" />

                    <div className="row-buttons">
                        <button
                            className="button"
                            disabled={loading}
                        onClick={() => navigate(-1)}
                        >Back</button>

                        {!editing && (
                            <button
                                className="button"
                                disabled={loading}
                                onClick={() => setEditing(true)}
                            >Edit</button>
                        )}

                        {editing && (
                            <>
                                <button
                                    className="button"
                                    disabled={loading}
                                    onClick={handleCancelEdit}
                                >Cancel Edits</button>

                                <button
                                    className="button green"
                                    disabled={loading}
                                    onClick={handleSaveEdits}
                                >{loading ? "Loading..." : "Save Edits"}</button>
                            </>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default User;