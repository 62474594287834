import React from 'react';
import "../styles/CSS/base.css";

const Header = ({ orgName }) => {
    return (
        <div className="header">
            <div>
                <img src="../SwiftComply.png" alt="SwiftComply" className="logo" />
                <h1>Tester Registration</h1>
            </div>
            <div>
                <h2>{orgName}</h2>
            </div>
        </div>
    );
};

export default Header;