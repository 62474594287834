import React from "react";
import { Link } from "react-router-dom";
import LogoutButton from "../SCAdmin_Auth/LogoutButton";


function capitalize(str) {
	const lower = str.toLowerCase()

	return str.charAt(0).toUpperCase() + lower.slice(1)
}

export default function NavBar ({ onLogoutSuccess }) {

    return (
      <div className="header">
        <div className="header">
          <div>
            <Link to="/scadmin">
              <img
                src="../../SwiftComply.png"
                alt="SwiftComply"
                className="logo"
              />
            </Link>
            <h1>SwiftComply Admin Registration Dashboard</h1>
          </div>
          <div className="navbar_menu_item_user">
            <LogoutButton onLogoutSuccess={onLogoutSuccess} />
          </div>
        </div>
      </div>
    );
}
