import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Info from "./info";
import OrgInfoError from "./orgInfoError";
import Company from "./company";
import Admin from "./admin";
import Tester from "./tester";
import Submitted from "./submitted";
import CompanyMatches from "./companyMatches";
import TestKits from "./testKits";
import "../styles/CSS/base.css"
import { removeError } from "../utils/errors";
import { submitUserType, submitCompany, submitFinalInfo, submitAdminInfo, submitTesterInfo, submitTestKitInfo } from "../utils/submissions";
import { getOrgInfo } from "../utils/requests";
import Summary from "./summary";
import { combineUsers } from "../utils/combineUsers";
import FetchFailMessage from "./fetchFailMessage";
import OrgHome from "./orgHome";
import Header from "./header";
import OrgHomeHeader from "./orgHomeHeader";

const Form = () => {

    // ----------------------------------------------------------------------
    // State variable declarations:
    // ----------------------------------------------------------------------

    const [pageLoading, setPageLoading] = useState(true);
    const hashValue = useParams().hash;
    const [fetchFailure, setFetchFailure] = useState(false);
    const [orgInfoErrorCode, setOrgInfoErrorCode] = useState("");
    const [formUserType, setFormUserType] = useState("tester");
    const [orgInfo, setOrgInfo] = useState({});

    const [formPageNum, setFormPageNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const [infoErrors, setInfoErrors] = useState({});
    const [companyErrors, setCompanyErrors] = useState({});
    const [companyMatchList, setCompanyMatchList] = useState([]);
    const [chosenCompany, setChosenCompany] = useState({});
    const [adminErrors, setAdminErrors] = useState({});
    const [testerErrors, setTesterErrors] = useState({});
    const [certErrors, setCertErrors] = useState({});
    const [testKitErrors, setTestKitErrors] = useState({});
    const [userErrors, setUserErrors] = useState({});

    const [showOrgHome, setShowOrgHome] = useState(true);


    const [companyInfo, setCompanyInfo] = useState([{
        companyID: "",
        companyName: "",
        companyPhone: "",
        companyAddress1: "",
        companyAddress2: "",
        companyCity: "",
        companyState: "",
        companyZip: ""
    }]);

    const [adminInfo, setAdminInfo] = useState([{
        userType: "admin",
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userPhone: ""
    }]);

    // Initial tester/cert state is set once our initial
    // request to the API returns with the number of required
    // certs.
    const [testerInfo, setTesterInfo] = useState([]);

    const [testKitInfo, setTestKitInfo] = useState([{
        kitSerial: "",
        kitMfr: "",
        kitModel: "",
        kitCalibDate: "",
        kitCalibDoc: null,
        kitCalibDocLink: ""
    }])

    // ----------------------------------------------------------------------
    // State variable change functions:
    // ----------------------------------------------------------------------

    const resetAdminInfo = () => {
        const newAdmin = {
            userType: "admin",
            userFirstName: "",
            userLastName: "",
            userEmail: "",
            userPhone: ""
        };

        setAdminInfo([newAdmin]);
    }

    const resetTesterInfo = () => {
        const newUserCert = {
            certNum: "",
            certExp: "",
            certAgency: "",
            certType: "",
            certDoc: null,
            certDocLink: ""
        };

        const newTester = {
            userType: "tester",
            userFirstName: "",
            userLastName: "",
            userEmail: "",
            userPhone: "",
            userCerts: []
        };

        // Push copies of newUserCert to newTester's userCerts.
        // Push the amount required by the org.
        for (let i = 0; i < orgInfo.number_of_certs; i++) {
            newTester.userCerts.push({ ...newUserCert });
        }

        setTesterInfo([newTester]);
    }

    const resetTestKitInfo = () => {
        const newTestKit = {
            kitSerial: "",
            kitMfr: "",
            kitModel: "",
            kitCalibDate: "",
            kitCalibDoc: null,
            kitCalibDocLink: ""
        };

        setTestKitInfo([newTestKit]);
    }

    const handleUserTypeChange = (userType) => {
        setFormUserType(userType)
        // We need to reset the admin and tester values in the event that a
        // user selected "admin", added additional testers/admins, then went
        // back and selected "tester".
        resetAdminInfo();
        resetTesterInfo();

        // We also need to reset the respective errors.
        setAdminErrors({});
        setTesterErrors({});
        setCertErrors({});
    }

    const handleCompanyEntry = (event) => {
        const newCompanyInfo = JSON.parse(JSON.stringify(companyInfo));
        newCompanyInfo[0][event.target.name] = event.target.value;
        setCompanyInfo(newCompanyInfo);

        // Clear any errors for the field being modified.
        let newCompanyErrors = removeError(0, event.target.name, companyErrors);
        setCompanyErrors(newCompanyErrors);
    }

    const handleAdminEntry = (idx, event) => {
        const newAdminInfo = JSON.parse(JSON.stringify(adminInfo));
        newAdminInfo[idx][event.target.name] = event.target.value;
        setAdminInfo(newAdminInfo);

        // Clear any errors for the field being modified.
        let newAdminErrors = removeError(idx, event.target.name, adminErrors);
        setAdminErrors(newAdminErrors);
    }

    const handleTesterEntry = (idx, event) => {
        const newTesterInfo = _.cloneDeep(testerInfo);
        newTesterInfo[idx][event.target.name] = event.target.value;
        setTesterInfo(newTesterInfo);

        // Clear any errors for the field being modified.
        let newTesterErrors = removeError(idx, event.target.name, testerErrors);
        setTesterErrors(newTesterErrors);
    }

    const handleCertEntry = (testerIdx, certIdx, event) => {
        const newTesterInfo = _.cloneDeep(testerInfo);

        // We need to check if the input is a file type, so we can set the
        // state to the file. Otherwise we can set the state to the value.
        if (event.target.name === "certDoc") {
            newTesterInfo[testerIdx].userCerts[certIdx][event.target.name] = event.target.files[0];
            newTesterInfo[testerIdx].userCerts[certIdx].kitCalibDocLink = "";
        } else {
            newTesterInfo[testerIdx].userCerts[certIdx][event.target.name] = event.target.value;
        }

        // Ensure cert type is reset when a different agency is picked.
        if (event.target.name === "certAgency") {
            newTesterInfo[testerIdx].userCerts[certIdx].certType = "";
        }

        setTesterInfo(newTesterInfo);


        // Clear any errors for the field being modified.
        if (certErrors[testerIdx] && Object.keys(certErrors[testerIdx]).length > 0) {
            // We need to ensure that certErrors has entries, otherwise
            // the logic below attempts to read/write to undefined.
            let newSpecificCertErrors = removeError(certIdx, event.target.name, certErrors[testerIdx]);
            let newCertErrors = JSON.parse(JSON.stringify(certErrors));
            newCertErrors[testerIdx] = newSpecificCertErrors
            setCertErrors(newCertErrors);
        }
    }

    const handleTestKitEntry = (idx, event) => {
        const newTestKitInfo = _.cloneDeep(testKitInfo);

        // We need to check if the input is a file type, so we can set the
        // state to the file. Otherwise we can set the state to the value.
        if (event.target.name === "kitCalibDoc") {
            newTestKitInfo[idx][event.target.name] = event.target.files[0];
            newTestKitInfo[idx].kitCalibDocLink = "";
        } else {
            newTestKitInfo[idx][event.target.name] = event.target.value;
        }

        setTestKitInfo(newTestKitInfo);

        // Clear any errors for the field being modified.
        let newTestKitErrors = removeError(idx, event.target.name, testKitErrors);
        setTestKitErrors(newTestKitErrors);
    }

    const addAdmin = () => {
        // Clear admin errors, so errors are not transferred visually.
        setAdminErrors({});

        const newAdmin = {
            userType: "admin",
            userFirstName: "",
            userLastName: "",
            userEmail: "",
            userPhone: ""
        }

        // Create a deep copy of adminInfo, and append the new admin.
        const newAdminInfo = JSON.parse(JSON.stringify(adminInfo));
        newAdminInfo.push(newAdmin);

        setAdminInfo(newAdminInfo);
    }

    const addTester = (requiredCerts) => {
        // Clear tester & cert errors, so errors are not transferred visually.
        setTesterErrors({});
        setCertErrors({});

        const newUserCert = {
            certNum: "",
            certExp: "",
            certAgency: "",
            certType: "",
            certDoc: null,
            certDocLink: ""
        }

        const newTester = {
            userType: "tester",
            userFirstName: "",
            userLastName: "",
            userEmail: "",
            userPhone: "",
            userCerts: []
        }

        // Push copies of newUserCert to newTester's userCerts.
        // Push the amount required by the org.
        for (let i = 0; i < requiredCerts; i++) {
            newTester.userCerts.push({ ...newUserCert });
        }

        // Create a deep copy of testerInfo, and append the new tester.
        const newTesterInfo = _.cloneDeep(testerInfo);
        newTesterInfo.push(newTester);

        setTesterInfo(newTesterInfo);
    }

    const addCert = (testerIdx) => {
        // Clear cert errors, so errors are not transferred visually.
        setCertErrors({});

        const newUserCert = {
            certNum: "",
            certExp: "",
            certAgency: "",
            certType: "",
            certDoc: null,
            certDocLink: ""
        }

        // Create a deep copy of testerInfo.
        const newTesterInfo = _.cloneDeep(testerInfo);

        // Push an empty cert to the specified tester's existing certs.
        newTesterInfo[testerIdx].userCerts.push(newUserCert);

        setTesterInfo(newTesterInfo);
    }

    const addTestKit = () => {
        // Clear test kit errors, so errors are not transferred visually.
        setTestKitErrors({});

        const newTestKit = {
            kitSerial: "",
            kitMfr: "",
            kitModel: "",
            kitCalibDate: "",
            kitCalibDoc: null,
            kitCalibDocLink: ""
        }

        // Create a deep copy of testKitInfo, and append the new test kit.
        const newTestKitInfo = _.cloneDeep(testKitInfo);

        newTestKitInfo.push(newTestKit);

        setTestKitInfo(newTestKitInfo);
    }

    const removeAdmin = (idx) => {
        // Clear admin errors, so errors are not transferred visually.
        setAdminErrors({});

        if (idx > 0) {
            const newAdminInfo = JSON.parse(JSON.stringify(adminInfo));
            newAdminInfo.splice(idx, 1);
            setAdminInfo(newAdminInfo);
        }
    }

    const removeTester = (idx) => {
        // Clear tester & cert errors, so errors are not transferred visually.
        setTesterErrors({});
        setCertErrors({});

        if (idx > 0) {
            const newTesterInfo = _.cloneDeep(testerInfo);
            newTesterInfo.splice(idx, 1);
            setTesterInfo(newTesterInfo);
        }
    }

    const removeCert = (testerIdx, certIdx, requiredNumOfCerts) => {
        // Clear cert errors, so errors are not transferred visually.
        setCertErrors({});

        if (certIdx >= requiredNumOfCerts) {
            const newTesterInfo = _.cloneDeep(testerInfo);
            newTesterInfo[testerIdx].userCerts.splice(certIdx, 1);
            setTesterInfo(newTesterInfo);
        }
    }

    const removeTestKit = (idx) => {
        // Clear test kit errors, so errors are not transferred visually.
        setTestKitErrors({});

        if (idx > 0) {
            const newTestKitInfo = _.cloneDeep(testKitInfo);

            newTestKitInfo.splice(idx, 1);
            setTestKitInfo(newTestKitInfo);
        }
    }

    // Handle the selection of a company from company matches page.
    const chooseCompany = (e, newID, companyMatchesIdx) => {
        e.preventDefault();

        // Update only the "companyID" of the company input state.
        // The final submit route will ignore any user entered
        // company info if the ID is present.

        // If the user went back and selected their own input data,
        // we are resetting the ID to an empty string.
        const newCompanyInfo = JSON.parse(JSON.stringify(companyInfo));
        newCompanyInfo[0].companyID = newID;
        setCompanyInfo(newCompanyInfo);

        // Set the chosen company to show on the summary page.
        if (companyMatchesIdx > -1) {
            setChosenCompany(companyMatchList[companyMatchesIdx]);
        } else {
            setChosenCompany({});
        }

        // Advance the page after a selection has been made.
        nextFormPage(e);
    }

    // ----------------------------------------------------------------------
    // Form Page Handling:
    // ----------------------------------------------------------------------

    // The "next" button is also the "submit" button.
    const nextFormPage = async (e) => {
        e.preventDefault();

        // The case we are checking is the current page.
        // The default case is to advance the page by 1, however
        // in certain scenarios (e.g. the user is a tester and we
        // want to skip the admin page) we will advance the page
        // by more than 1 to skip a page. Additionally, we may
        // send an API request based on the page.

        // The case we are checking is the current page.
        switch (formPageNum) {
            case 0:
                const userTypeResCode = await submitUserType(setLoading, setInfoErrors, formUserType);

                if (userTypeResCode === "FETCH FAILURE") {
                    setFetchFailure(true);
                } else if (userTypeResCode === "USER TYPE ERROR") {
                    // We do not want to advance if we cannot verify the user type.
                    document.getElementsByClassName("page")[0].scrollTo(0, 0);
                    console.log(`Request produced code: ${userTypeResCode}`);
                    break;
                } else {
                    setFormPageNum((prevPageNum) => prevPageNum + 1);
                }
                break;
            case 1:
                // Validate company inputs:
                const companyResCode = await submitCompany(setCompanyMatchList, companyInfo, setCompanyInfo, setCompanyErrors, setLoading, setChosenCompany);

                if (companyResCode === "FETCH FAILURE") {
                    setFetchFailure(true);
                } else if (companyResCode === "No Matches or Errors" || companyResCode === "Found Exact Match") {
                    // Advance the page if the submit was a success.
                    if (formUserType === "tester") {
                        // Skip the admin page and the company match page.
                        setFormPageNum((prevPageNum) => prevPageNum + 3)
                    } else if (formUserType === "admin") {
                        // Skip the company match page.
                        setFormPageNum((prevPageNum) => prevPageNum + 2)
                    }
                } else if (companyResCode === "Found Possible Matches") {
                    // Advance to the company match page.
                    setFormPageNum((prevPageNum) => prevPageNum + 1);
                } else {
                    // If there are errors (or if we receive a code we don't recognize),
                    // don't advance the page.
                    document.getElementsByClassName("page")[0].scrollTo(0, 0);
                    console.log(`Request produced code: ${companyResCode}`);
                }
                break;
            case 2:
                if (formUserType === "tester") {
                    // Skip the admin page.
                    setFormPageNum((prevPageNum) => prevPageNum + 2)
                } else if (formUserType === "admin") {
                    setFormPageNum((prevPageNum) => prevPageNum + 1)
                }
                break;
            case 3:
                // Validate admin info.
                const adminResCode = await submitAdminInfo(setLoading, adminInfo, testerInfo, setAdminErrors);

                if (adminResCode === "FETCH FAILURE") {
                    setFetchFailure(true);
                } else if (adminResCode === "SUCCESS") {
                    // Advance the page if the submit was a success.
                    setFormPageNum((prevPageNum) => prevPageNum + 1);
                } else {
                    // If there are errors (or if we receive a code we don't recognize),
                    // don't advance the page.
                    document.getElementsByClassName("page")[0].scrollTo(0, 0);
                    console.log(`Request produced code: ${adminResCode}`);
                }
                break;
            case 4:
                // Validate tester/cert info.
                const testerResCode = await submitTesterInfo(setLoading, testerInfo, adminInfo, setTesterErrors, setCertErrors);

                if (testerResCode === "FETCH FAILURE") {
                    setFetchFailure(true);
                } else if (testerResCode === "SUCCESS") {
                    setFormPageNum((prevPageNum) => prevPageNum + 1);
                } else {
                    // If there are errors (or if we receive a code we don't recognize),
                    // don't advance the page.
                    document.getElementsByClassName("page")[0].scrollTo(0, 0);
                    console.log(`Request produced code: ${testerResCode}`);
                }
                break;
            case 5:
                // Validate test kit info.
                const testKitResCode = await submitTestKitInfo(setLoading, testKitInfo, setTestKitErrors);

                if (testKitResCode === "FETCH FAILURE") {
                    setFetchFailure(true);
                } else if (testKitResCode === "SUCCESS") {
                    setFormPageNum((prevPageNum) => prevPageNum + 1);
                } else {
                    // If there are errors (or if we receive a code we don't recognize),
                    // don't advance the page.
                    document.getElementsByClassName("page")[0].scrollTo(0, 0);
                    console.log(`Request produced code: ${testKitResCode}`);
                }
                break;
            case 6:
                // Final submit.
                const finalResCode = await submitFinalInfo(hashValue, companyInfo, adminInfo, testerInfo, testKitInfo, setUserErrors, setLoading);

                if (finalResCode === "FETCH FAILURE") {
                    setFetchFailure(true);
                } else if (finalResCode === "SUCCESS") {
                    setSubmitSuccess(true);
                } else {
                    document.getElementsByClassName("page")[0].scrollTo(0, 0);
                    console.log(`Request produced code: ${finalResCode}`);
                }
                break;
            default:
                // We should never hit the default case. This means the
                // user has somehow traveled to a page we do not have.
                console.log("PAGE ERROR: current page not recognized.")
        }
    }

    const previousFormPage = () => {
        switch (formPageNum) {
            case 0:
                // Don't move past the first page.
                break;
            case 1:
                setFormPageNum((prevPageNum) => prevPageNum - 1);
                break;
            case 2:
                setFormPageNum((prevPageNum) => prevPageNum - 1);
                break;
            case 3:
                // Only admin users can access this page. We need to return them
                // to the company match page (if there were matches) or to the
                // company page (if there were no matches).
                if (companyMatchList.length > 0) {
                    setFormPageNum((prevPageNum) => prevPageNum - 1)
                } else {
                    setFormPageNum((prevPageNum) => prevPageNum - 2)
                }
                break;
            case 4:
                if (formUserType === "tester") {
                    // If the user is a tester, we need to return them to either
                    // the company match page, or the company page (depending on
                    // whether there were company matches)--and skip the admin
                    // page.
                    if (companyMatchList.length > 0) {
                        setFormPageNum((prevPageNum) => prevPageNum - 2)
                    } else {
                        setFormPageNum((prevPageNum) => prevPageNum - 3)
                    }
                } else if (formUserType === "admin") {
                    setFormPageNum((prevPageNum) => prevPageNum - 1)
                }
                break;
            case 5:
                setFormPageNum((prevPageNum) => prevPageNum - 1)
                break;
            case 6:
                setFormPageNum((prevPageNum) => prevPageNum - 1)
                break;
            default:
                // We should never hit the default case. This means the
                // user has somehow traveled to a page we do not have.
                console.log("PAGE ERROR: current page not recognized.")
        }
    }

    const getFormPage = () => {
        // Return the correct form page component based on the form page number.
        switch (formPageNum) {
            case 1:
                return <Company
                    companyInfo={companyInfo}
                    handleCompanyEntry={handleCompanyEntry}
                    companyErrors={companyErrors}
                />;
            case 2:
                return <CompanyMatches
                    companyMatchList={companyMatchList}
                    chooseCompany={chooseCompany}
                />
            case 3:
                return <Admin
                    adminInfo={adminInfo}
                    handleAdminEntry={handleAdminEntry}
                    removeAdmin={removeAdmin}
                    adminErrors={adminErrors}
                />;
            case 4:
                return <Tester
                    testerInfo={testerInfo}
                    handleTesterEntry={handleTesterEntry}
                    removeTester={removeTester}
                    orgCerts={orgInfo.org_associated_certs}
                    handleCertEntry={handleCertEntry}
                    testerErrors={testerErrors}
                    certErrors={certErrors}
                    addCert={addCert}
                    removeCert={removeCert}
                    requiredNumOfCerts={orgInfo.number_of_certs}
                    setTesterInfo={setTesterInfo}
                    setTesterErrors={setTesterErrors}
                    setCertErrors={setCertErrors}
                    orgHash={hashValue}
                />;
            case 5:
                return <TestKits
                    testKitInfo={testKitInfo}
                    setTestKitInfo={setTestKitInfo}
                    handleTestKitEntry={handleTestKitEntry}
                    removeTestKit={removeTestKit}
                    testKitErrors={testKitErrors}
                    setTestKitErrors={setTestKitErrors}
                    orgHash={hashValue}
                />
            case 6:
                return <Summary
                    companyInfo={Object.keys(chosenCompany).length > 0 ? [chosenCompany] : companyInfo}
                    combinedUsers={combineUsers(adminInfo, testerInfo)}
                    testKitInfo={testKitInfo}
                    userErrors={userErrors}
                    orgCerts={orgInfo.org_associated_certs}
                />
            default:
                return <Info
                    formUserType={formUserType}
                    handleUserTypeChange={handleUserTypeChange}
                    infoErrors={infoErrors}
                    orgInfo={orgInfo}
                />;
        }
    }

    const skipTesterPage = () => {
        // Reset the tester state. If we don't do this, tester inputs past
        // tester #1 will bypass validations and make it to the final submit.
        resetTesterInfo();

        // Then advance the page.
        setFormPageNum((prevPageNum) => prevPageNum + 1);
    }

    const skipTestKitPage = () => {
        // Reset the test kit state. If we don't do this, test kit inputs past
        // test kit #1 will bypass validations and make it to the final submit.
        resetTestKitInfo();

        // Then advance the page.
        setFormPageNum((prevPageNum) => prevPageNum + 1);
    }

    // ----------------------------------------------------------------------
    // useEffects (initial load API call & page scroll):
    // ----------------------------------------------------------------------

    // Fetch the organization info on the initial page load.
    useEffect(() => {
        async function requestInitialInfo() {
            const orgInfoRes = await getOrgInfo(hashValue);

            if (orgInfoRes.code === "FETCH FAILURE") {
                setFetchFailure(true);
            } else if (orgInfoRes.code !== "VALID URL") {
                setOrgInfoErrorCode(orgInfoRes.code);
            } else {
                setOrgInfo(orgInfoRes.message.orgInfo);
                
                // We can only set the initial tester state once
                // we know how many certs they should have.
                addTester(orgInfoRes.message.orgInfo.number_of_certs);
            }

            // "pageLoading" is different from "loading". "pageLoading" is set true
            // initially, and we need to set it false here for any content to render
            // after the initial load.
            setPageLoading(false);
        }

        requestInitialInfo()
    }, [hashValue])

    // Scroll to the top of the page for each form page switch.
    useEffect(() => {
        document.getElementsByClassName("page")[0].scrollTo(0, 0);
    }, [formPageNum])

    if (fetchFailure) {
        return (
            <FetchFailMessage />
        )
    }

    return (
        <>

            {showOrgHome && (
                <>
                    <OrgHomeHeader orgName={orgInfo.org_name} />
                    <div className="page">
                        <OrgHome orgInfo={orgInfo} setShowOrgHome={setShowOrgHome} orgInfoErrorCode={orgInfoErrorCode} />
                    </div>
                </>
            )}

            {!showOrgHome && (
                <>
                    <Header orgName={orgInfo.org_name} />
                    <div className="page">
                        <>
                            <img src="../form.svg" alt="form" className="background-icon" />
                            <form method="post" className="form">
                                {
                                    // We conditionally render the form based on whether it has
                                    // been submitted. The "submitted" page is separate component.
                                    // We also only want to display the page when we have loaded
                                    // necessary data.
                                    submitSuccess === false && pageLoading === false && orgInfoErrorCode === "" &&
                                    <>
                                        {getFormPage()}
                                        <div className="section print-hidden">
                                            <div className="buttons">

                                                {formPageNum === 6 &&
                                                    <button type="button" onClick={() => window.print()} className="button">Print</button>
                                                }

                                                {formPageNum !== 0 &&
                                                    <button type="button" onClick={previousFormPage} className="button">Back</button>
                                                    // We don't want a "back" button on the first page.
                                                }

                                                {formPageNum === 3 && adminInfo.length < 10 &&
                                                    <button type="button" onClick={addAdmin} className="button">Add Another Admin</button>
                                                    // "Add Admin" button for the admin page.
                                                }

                                                {formPageNum === 4 && formUserType === "admin" && testerInfo.length < 10 &&
                                                    <button type="button" onClick={() => addTester(orgInfo.number_of_certs)} className="button">Add Another Tester</button>
                                                    // "Add Tester" button for the tester page ONLY if the form submitter is an admin.
                                                }

                                                {formPageNum === 4 && formUserType === "admin" &&
                                                    <button type="button" onClick={() => skipTesterPage()} className="button">Skip</button>
                                                    // "Skip" button in case admins are just adding admins or themselves (bypass validations & leave an empty tester).
                                                }

                                                {formPageNum === 5 && testKitInfo.length < 10 &&
                                                    <button type="button" onClick={addTestKit} className="button">Add Another Test Kit</button>
                                                }

                                                {formPageNum === 5 &&
                                                    <button type="button" onClick={() => skipTestKitPage()} className="button">Skip</button>
                                                    // "Skip" button in case the user registering does not have a test kit for some reason.
                                                }

                                                {formPageNum !== 2 &&
                                                    // We don't want a next button on the company select page,
                                                    // since the select buttons will advance the page.
                                                    <button type="submit" onClick={nextFormPage} className="button green">
                                                        {loading === true ? "Loading..." : formPageNum === 6 ? "Submit" : "Next"}
                                                    </button>
                                                }

                                                {formPageNum === 2 &&
                                                    // Skip option for the company match page.
                                                    // This will assign the company ID to an empty string, in case a
                                                    // match company was selected previously and the user went back.
                                                    <button type="submit" className="button warning" onClick={(e) => chooseCompany(e, "", -1)}><span>Skip</span></button>
                                                }

                                            </div>
                                        </div>
                                    </>
                                }
                                {submitSuccess && <Submitted />}
                                {pageLoading && <>Page Loading...</>}
                                {orgInfoErrorCode !== "" && <OrgInfoError orgInfoErrorCode={orgInfoErrorCode} />}
                            </form>
                        </>
                    </div>
                </>
            )}

        </>
    )
}

export default Form;
