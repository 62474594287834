import React from "react";

import { getErrorMessages } from "../utils/errors";
import Tooltip from "./tooltip";

const Company = ({ companyInfo, handleCompanyEntry, companyErrors }) => {
  const errorMessages = getErrorMessages(companyErrors);

  return (
    <div className="section">
      <div className="sub-heading">Company Details</div>

      <div className="group">
        {errorMessages[0]?.length > 0 && (
          <div className="row">
            <div className="field">
              <ul className="errors">
                {errorMessages[0].map((error, idx) => (
                  <li key={idx}>{error}</li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className="row">
          <label
            className={
              companyErrors[0]?.companyName ? "field error-highlight" : "field"
            }
          >
            <div className="label">
              Company Name*
              <Tooltip
                text={
                  "Enter the name of your testing company. If you are self employed, please enter your full name."
                }
              />
            </div>
            <input
              name="companyName"
              value={companyInfo[0].companyName}
              onChange={(event) => handleCompanyEntry(event)}
            />
          </label>
          <label
            className={
              companyErrors[0]?.companyPhone ? "field error-highlight" : "field"
            }
          >
            Company Phone
            <input
              name="companyPhone"
              value={companyInfo[0].companyPhone}
              onChange={(event) => handleCompanyEntry(event)}
            />
          </label>
        </div>

        <div className="row">
          <label
            className={
              companyErrors[0]?.companyAddress1
                ? "field error-highlight"
                : "field"
            }
          >
            Company Address*
            <input
              name="companyAddress1"
              value={companyInfo[0].companyAddress1}
              onChange={(event) => handleCompanyEntry(event)}
            />
          </label>
        </div>
        <div className="row">
          <label
            className={
              companyErrors[0]?.companyAddress2
                ? "field error-highlight"
                : "field"
            }
          >
            Company Address Line 2
            <input
              name="companyAddress2"
              value={companyInfo[0].companyAddress2}
              onChange={(event) => handleCompanyEntry(event)}
            />
          </label>
        </div>

        <div className="row">
          <label
            className={
              companyErrors[0]?.companyCity ? "field error-highlight" : "field"
            }
          >
            City*
            <input
              name="companyCity"
              value={companyInfo[0].companyCity}
              onChange={(event) => handleCompanyEntry(event)}
            />
          </label>
          <label
            className={
              companyErrors[0]?.companyState ? "field error-highlight" : "field"
            }
          >
            State/Prov*
            <select
              type="select"
              name="companyState"
              value={companyInfo[0].companyState}
              onChange={(event) => handleCompanyEntry(event)}
            >
              <option value="">Please select</option>
              <option value="AL">AL</option>
              <option value="AK">AK</option>
              <option value="AZ">AZ</option>
              <option value="AR">AR</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="HI">HI</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="IA">IA</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="ME">ME</option>
              <option value="MD">MD</option>
              <option value="MA">MA</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MS">MS</option>
              <option value="MO">MO</option>
              <option value="MT">MT</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="NE">NE</option>
              <option value="NV">NV</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NY">NY</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VT">VT</option>
              <option value="VA">VA</option>
              <option value="WA">WA</option>
              <option value="DC">DC</option>
              <option value="WV">WV</option>
              <option value="WI">WI</option>
              <option value="WY">WY</option>
              <option value="AA">AA</option>
              <option value="AE">AE</option>
              <option value="AP">AP</option>
              <option value="AB">AB</option>
              <option value="BC">BC</option>
              <option value="MB">MB</option>
              <option value="NB">NB</option>
              <option value="NL">NL</option>
              <option value="NT">NT</option>
              <option value="NS">NS</option>
              <option value="NU">NU</option>
              <option value="ON">ON</option>
              <option value="PE">PE</option>
              <option value="QC">QC</option>
              <option value="SK">SK</option>
              <option value="YT">YT</option>
            </select>
          </label>
          <label
            className={
              companyErrors[0]?.companyZip ? "field error-highlight" : "field"
            }
          >
            Postal Code*
            <input
              name="companyZip"
              value={companyInfo[0].companyZip}
              onChange={(event) => handleCompanyEntry(event)}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default Company;
