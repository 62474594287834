import _ from "lodash";

// We need to combine admin inputs and tester inputs into 
// one user inputs array--before sending the data to the API.
export const combineUsers = (adminData, testerData) => {
    let userData = [];

    for (let admin of adminData) {
        // Because the admin page conditionally renders, there will be an empty
        // admin submission if the submitter is a tester. We need to exclude that.
        if (admin.userEmail) {
            userData.push({
                userType: admin.userType,
                userFirstName: admin.userFirstName,
                userLastName: admin.userLastName,
                userEmail: admin.userEmail,
                userPhone: admin.userPhone,
                userCerts: []
            })
        }
    }

    // Since we give admins the option to skip adding a tester, we have an empty tester in state.
    // This will check to make sure that any tester info that is submitted has info.
    for (let tester of testerData) {
        if (tester.userEmail) {
            userData.push(tester)
        }
    }

    // Give the new userData a deep clone. This makes some of the stuff we did above redundant,
    // but we need to make sure we aren't mutating state later on using this data.
    userData = _.cloneDeep(userData);

    return userData;
}