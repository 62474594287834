import APIService from "../APIService";


export const getOrgInfo = async (hashValue) => {

    try {
        const res = await APIService.getOrgInfo(hashValue);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export const getAllCertifications = async (currentToken) => {
    try {
        const res = await APIService.getCertAgencies(currentToken);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            code: "FETCH FAILURE",
        };
    }
};

export const getAllCertificationTypes = async (token) => {

    try {
        const res = await APIService.getCertTypes(token);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export const getOrgInfoSCA = async (formatted_encode, token) => {

    try {
        const res = await APIService.getOrgInfoSCA(formatted_encode, token);
        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export const getOrgs = async (token) => {

    try {

        const res = await APIService.getAllOrgsForReports(token);
        return res

    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export const getSignupCount = async (orgId, token) => {

    try {
        // // Simulate response time.
        // const sleep = ms => new Promise(
        //     resolve => setTimeout(resolve, ms));
        // await sleep(1000);

        // const res = {
        //     "message": 19,
        //     "code": "SUCCESS"
        // }

        // const res = {
        //     "message": ["Could not retrieve organizations signup count."],
        //     "code": "GET SIGNUP COUNT ERROR"
        // }

        const res = await APIService.getTotalSignUpsCount(orgId, token);

        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export const getUsers = async (orgId, query, page, token) => {

    try {
        // Simulate response time.
        // const sleep = ms => new Promise(
        //     resolve => setTimeout(resolve, ms));
        // await sleep(1000);

        const payload = {
            "orgId": orgId,
            "page": page,
            "searchQuery": query
        }

        let res = await APIService.fetchUsers(payload, token);

        // if (page === 1) {
        //     res = {
        //         "message": {
        //             "currentPage": 1,
        //             "finalPage": 2,
        //             "usersInfo": {
        //                 "152": {
        //                     "userType": "tester",
        //                     "userFirstName": "Jack",
        //                     "userLastName": "Johnson",
        //                     "userEmail": "jjohnson@mail.co",
        //                     "userPhone": "533-324-2353",
        //                     "userEnrolled": true
        //                 },
        //                 "239": {
        //                     "userType": "tester",
        //                     "userFirstName": "Tim",
        //                     "userLastName": "Apple",
        //                     "userEmail": "timapple@mail.co",
        //                     "userPhone": "532-463-4634",
        //                     "userEnrolled": false
        //                 },
        //                 "483": {
        //                     "userType": "admin",
        //                     "userFirstName": "Jane",
        //                     "userLastName": "Doe",
        //                     "userEmail": "jdoe@mail.co",
        //                     "userPhone": "534-324-9876",
        //                     "userEnrolled": true
        //                 },
        //                 "592": {
        //                     "userType": "tester",
        //                     "userFirstName": "Emily",
        //                     "userLastName": "Smith",
        //                     "userEmail": "esmith@mail.co",
        //                     "userPhone": "535-123-4567",
        //                     "userEnrolled": false
        //                 },
        //                 "384": {
        //                     "userType": "admin",
        //                     "userFirstName": "Michael",
        //                     "userLastName": "Brown",
        //                     "userEmail": "mbrown@mail.co",
        //                     "userPhone": "536-234-5678",
        //                     "userEnrolled": true
        //                 },
        //                 "476": {
        //                     "userType": "tester",
        //                     "userFirstName": "Sarah",
        //                     "userLastName": "Taylor",
        //                     "userEmail": "staylor@mail.co",
        //                     "userPhone": "537-345-6789",
        //                     "userEnrolled": false
        //                 },
        //                 "254": {
        //                     "userType": "admin",
        //                     "userFirstName": "David",
        //                     "userLastName": "Lee",
        //                     "userEmail": "dlee@mail.co",
        //                     "userPhone": "538-456-7890",
        //                     "userEnrolled": true
        //                 },
        //                 "867": {
        //                     "userType": "tester",
        //                     "userFirstName": "Laura",
        //                     "userLastName": "Moore",
        //                     "userEmail": "lmoore@mail.co",
        //                     "userPhone": "539-567-8901",
        //                     "userEnrolled": false
        //                 },
        //                 "531": {
        //                     "userType": "admin",
        //                     "userFirstName": "James",
        //                     "userLastName": "Wilson",
        //                     "userEmail": "jwilson@mail.co",
        //                     "userPhone": "540-678-9012",
        //                     "userEnrolled": true
        //                 },
        //                 "198": {
        //                     "userType": "tester",
        //                     "userFirstName": "Linda",
        //                     "userLastName": "Martin",
        //                     "userEmail": "lmartin@mail.co",
        //                     "userPhone": "541-789-0123",
        //                     "userEnrolled": false
        //                 },
        //                 "473": {
        //                     "userType": "admin",
        //                     "userFirstName": "Robert",
        //                     "userLastName": "Clark",
        //                     "userEmail": "rclark@mail.co",
        //                     "userPhone": "542-890-1234",
        //                     "userEnrolled": true
        //                 },
        //                 "684": {
        //                     "userType": "tester",
        //                     "userFirstName": "Barbara",
        //                     "userLastName": "Hall",
        //                     "userEmail": "bhall@mail.co",
        //                     "userPhone": "543-901-2345",
        //                     "userEnrolled": false
        //                 },
        //                 "920": {
        //                     "userType": "admin",
        //                     "userFirstName": "Thomas",
        //                     "userLastName": "Allen",
        //                     "userEmail": "tallen@mail.co",
        //                     "userPhone": "544-012-3456",
        //                     "userEnrolled": true
        //                 },
        //                 "357": {
        //                     "userType": "tester",
        //                     "userFirstName": "Patricia",
        //                     "userLastName": "King",
        //                     "userEmail": "pking@mail.co",
        //                     "userPhone": "545-123-4567",
        //                     "userEnrolled": false
        //                 },
        //                 "412": {
        //                     "userType": "admin",
        //                     "userFirstName": "Joseph",
        //                     "userLastName": "Wright",
        //                     "userEmail": "jwright@mail.co",
        //                     "userPhone": "546-234-5678",
        //                     "userEnrolled": true
        //                 }
        //             }
        //         },
        //         "code": "SUCCESS"
        //     }
        // } else if (page === 2) {
        //     res = {
        //         "message": {
        //             "currentPage": 2,
        //             "finalPage": 2,
        //             "usersInfo": {
        //                 "345": {
        //                     "userType": "admin",
        //                     "userFirstName": "Brian",
        //                     "userLastName": "Joe",
        //                     "userEmail": "bj@mail.co",
        //                     "userPhone": "544-012-3456",
        //                     "userEnrolled": true
        //                 },
        //                 "793": {
        //                     "userType": "tester",
        //                     "userFirstName": "Krista",
        //                     "userLastName": "Klang",
        //                     "userEmail": "kk@mail.co",
        //                     "userPhone": "545-123-4567",
        //                     "userEnrolled": false
        //                 },
        //                 "689": {
        //                     "userType": "admin",
        //                     "userFirstName": "Boris",
        //                     "userLastName": "Womble",
        //                     "userEmail": "bw@mail.co",
        //                     "userPhone": "546-234-5678",
        //                     "userEnrolled": true
        //                 }
        //             }
        //         },
        //         "code": "SUCCESS"
        //     }
        // }

        // res = {
        //     "message": {
        //         "currentPage": 1,
        //         "finalPage": 1,
        //         "usersInfo": {}
        //     },
        //     "code": "SUCCESS"
        // }

        // res = {
        //     "message": "Could not retrieve users.",
        //     "code": "GET USERS ERROR"
        // }

        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}

export const getUserInfo = async (userId, token) => {

    try {
        // Simulate response time.
        const sleep = ms => new Promise(
            resolve => setTimeout(resolve, ms));
        await sleep(1000);

        let res = {};

        res = {
            "message": {
                "userType": "admin",
                "userFirstName": "Brian",
                "userLastName": "Joe",
                "userEmail": "bj@mail.co",
                "userPhone": "544-012-3456",
                "userOrgs": {
                    "7": {
                        "name": "City of Atlanta, GA",
                        "enrolled": true
                    },
                    "9": {
                        "name": "City of Madison, TN",
                        "enrolled": false
                    }
                },
                "userServiceProviders": {
                    "34": "Eastfield Backflow & Fire",
                    "29": "Brian Joe's Plumbing"
                },
                "userCerts": {
                    "233": {
                        "certNum": "3462346",
                        "certExp": "2025-01-01",
                        "certAgency": "AWWA",
                        "certType": "Cross Connection Certified",
                        "certDocLink": "https://fake.link/g3g3-3g333vf-3t3b-33efd"
                    },
                    "288": {
                        "certNum": "854656",
                        "certExp": "2025-12-07",
                        "certAgency": "ABPA",
                        "certType": "Backflow Testing Certified",
                        "certDocLink": "https://fake.link/2grw-2hbtj-2r2r-wgwg"
                    }
                }
            },
            "code": "SUCCESS"
        }

        return res;
    } catch (error) {
        console.log(error);
        return {
            "message": "API fetch failed.",
            "code": "FETCH FAILURE"
        }
    }
}