import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";


import Form from "./components/form";
import SCAdmin from "./components/SCAdmin/scAdmin";
import EditSearchOrganizations from "./components/SCAdmin/orgSearch";
import CreateOrg from "./components/SCAdmin/createOrg";
import ActiveOrganizations from "./components/SCAdmin/activeOrgs";
import InactiveOrganizations from "./components/SCAdmin/inactiveOrgs";
import Layout from "./components/Layout/layout";
import ViewOrEditOrg from "./components/SCAdmin/viewAndEditOrg";
import Reports from "./components/SCAdmin/reports";
import Certifications from "./components/SCAdmin/certifications";
import Home from "./components/home";
import TestComponent from "./components/SCAdmin_Auth/FirebaseAuth";
import { TokenProvider } from "./TokenContext";
import Users from "./components/SCAdmin/users";
import User from "./components/SCAdmin/user";

function App() {
  const [showDialog, setShowDialog] = useState("");
  const [token, setToken] = useState(null); 

  // return (
  //   <>
  //   <TokenProvider token={token}>
  //     <Routes>
  //       <Route path="/form/:hash" element={<Form />} />
  //       <Route element={<Layout setShowDialog={setShowDialog} />}>
  //         <Route path="/scadmin" exact={true} Component={SCAdmin} />
  //         <Route path="/scadmin/reports" exact={true} Component={Reports} />
  //         <Route
  //           path="/scadmin/create"
  //           exact={true}
  //           Component={() => <CreateOrg setShowDialog={setShowDialog} />}
  //           />
  //         <Route
  //           path="/scadmin/firebase/button"
  //           exact={true}
  //           Component={TestComponent}
  //           />
  //         <Route
  //           path="/scadmin/active"
  //           exact={true}
  //           Component={ActiveOrganizations}
  //           />
  //         <Route
  //           path="/scadmin/inactive"
  //           exact={true}
  //           Component={InactiveOrganizations}
  //           />
  //         <Route
  //           path="/scadmin/certifications"
  //           exact={true}
  //           Component={Certifications}
  //           />
  //         <Route
  //           path="/scadmin/search"
  //           exact={true}
  //           Component={() => (
  //             <EditSearchOrganizations
  //             showDialog={showDialog}
  //             setShowDialog={setShowDialog}
  //             />
  //             )}
  //             />
  //         <Route
  //           path="/scadmin/vieworedit/:orgNameParam"
  //           Component={() => (
  //             <ViewOrEditOrg
  //             showDialog={showDialog}
  //             setShowDialog={setShowDialog}
  //             />
  //             )}
  //             />
  //       </Route>
  //       <Route path="" element={<Home />} />
  //     </Routes>
  //     </TokenProvider>
  //   </>
  // );
  return (
    <Routes>
      <Route path="/form/:hash" element={<Form />} />
      <Route
        path="/scadmin/*"
        element={
          <TokenProvider token={token}>
            <Layout setShowDialog={setShowDialog} />
          </TokenProvider>
        }
      >
        {/* Nested routes under /scadmin */}
        <Route index element={<SCAdmin />} />
        <Route path="reports" element={<Reports />} />
        <Route path="create" element={<CreateOrg setShowDialog={setShowDialog} />} />
        <Route path="firebase/button" element={<TestComponent />} />
        <Route path="active" element={<ActiveOrganizations />} />
        <Route path="inactive" element={<InactiveOrganizations />} />
        <Route path="certifications" element={<Certifications />} />
        <Route path="search" element={<EditSearchOrganizations showDialog={showDialog} setShowDialog={setShowDialog} />} />
        <Route path="vieworedit/:orgNameParam" element={<ViewOrEditOrg showDialog={showDialog} setShowDialog={setShowDialog} />} />
        <Route path="users/:userId" element={<User />} />
        <Route path="users" element={<Users />} />
      </Route>
      <Route path="/" element={<Home />} />
    </Routes>
  );
}
export default App
