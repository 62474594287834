import React, { useEffect, useState } from "react";

const SearchSelect = ({ options, setFunction, additionalFunction, setCheckFunction }) => {
    /*
        - The component receives:
            - "options": the dropdown options {id: option}.
            - "setFunction": the useState set function to set the value in the parent component.
            - "additionalFunction": an additional (optional) function that is passed the id selected.

        - When the component mounts it loads the options passed in.
        - The options are sorted alphabetically before being displayed.
        - The options dropdown will only show if there is at least one result from the query
          and the query is not empty.

        - When the component mounts, we also need to reset "results"--since after an option is selected, if
          the component re-renders, it will satisfy the conditions to display the results even if an option
          is selected.
    */
    const [queryValue, setQueryValue] = useState("");
    const [results, setResults] = useState([]);

    const handleQuery = (e) => {
        setQueryValue(e.target.value)
        setResults(Object.keys(options).filter((id) => options[id].toLowerCase().includes((e.target.value).toLowerCase())));
        if (!Object.values(results)[queryValue]) setCheckFunction(false);
    }

    const handleSelect = (id) => {
        setFunction(id)
        if (typeof additionalFunction === "function") additionalFunction(id);
        setQueryValue(options[id])
        setResults([])
        setCheckFunction(true);
    }

    const alphaSort = (idList) => {
        return idList.sort((a, b) => {
            if (options[a].toLowerCase() < options[b].toLowerCase()) {
                return -1;
            }
            if (options[a].toLowerCase() > options[b].toLowerCase()) {
                return 1;
            }
            return 0;
        })
    }

    useEffect(() => {
        options && setResults(alphaSort(Object.keys(options)));
        setResults([]);
    }, [options])

    return (
        <div style={{height: (results.length > 0 && queryValue !== "") ? "22rem" : "auto"}}>
            <input type="search" value={queryValue} onChange={(e) => handleQuery(e)} />
            {(results.length > 0 && queryValue !== "") && (
                <ul className="search-results">
                    {alphaSort(results).map((id) => (
                        <li key={id} onClick={() => handleSelect(id)}>
                            {options[id]}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default SearchSelect;