// Frontend validations for basic checks before API validations.

import { countEmails, uniqueEmail } from "./uniqueEmailCheck";

export const preValidateCompany = (companyInfo) => {
    // Accepts a 'companyInfo' argument (array of one 
    // object containing user entered company info).

    const companyErrors = {};

    companyInfo.forEach((company, index) => {
        // Temporarily add this company's fields to errors, so 
        // we don't have to check if it's in there for each validation.
        companyErrors[index] = {
            companyName: [],
            companyPhone: [],
            companyAddress1: [],
            companyAddress2: [],
            companyCity: [],
            companyState: [],
            companyZip: []
        }

        // Empty field checks:
        if (company["companyName"] === "") companyErrors[index]["companyName"].push("Field required.");
        if (company["companyAddress1"] === "") companyErrors[index]["companyAddress1"].push("Field required.");
        if (company["companyCity"] === "") companyErrors[index]["companyCity"].push("Field required.");
        if (company["companyState"] === "") companyErrors[index]["companyState"].push("Field required.");
        if (company["companyZip"] === "") companyErrors[index]["companyZip"].push("Field required.");

        // Length checks:
        if (company["companyName"].length > 255) companyErrors[index]["companyName"].push("Company name must be 255 characters or less.");
        if (company["companyPhone"].length > 50) companyErrors[index]["companyPhone"].push("Company phone must be 50 characters or less.");
        if (company["companyAddress1"].length > 255) companyErrors[index]["companyAddress1"].push("Company address must be 255 characters or less.");
        if (company["companyAddress2"].length > 255) companyErrors[index]["companyAddress2"].push("Company address line 2 must be 255 characters or less.");
        if (company["companyCity"].length > 255) companyErrors[index]["companyCity"].push("City must be 255 characters or less.");
        if (company["companyZip"].length > 255) companyErrors[index]["companyZip"].push("Zip code must be 255 characters or less.");

        // Regex:
        const canadaStates = new Set(["AB","BC","MB","NB","NL","NT","NS","NU","ON","PE","QC","SK","YT"]);
        if (!canadaStates.has(company["companyState"])) {
            if (company["companyZip"] && !/^\d{5}(?:-\d{4})?$/.test(company["companyZip"])) companyErrors[index]["companyZip"].push("Zip code invalid.");
        }


        // Remove the empty error fields.
        for (const [field, errors] of Object.entries(companyErrors[index])) {
            if (errors.length < 1) delete companyErrors[index][field];
        }
    })

    // Remove companies from the company errors if they don't have any errors.
    for (const [index, fields] of Object.entries(companyErrors)) {
        if (Object.keys(fields).length < 1) delete companyErrors[index];
    }

    return companyErrors;
}

export const preValidateAdmins = (adminInfo, testerInfo) => {
    const adminErrors = {};

    // Email counts are produced upon preValidation so that's why
    // we need to receive adminInfo and testerInfo here.
    const emailCounts = countEmails(adminInfo, testerInfo);

    adminInfo.forEach((admin, index) => {
        // Temporarily add this admin's fields to errors, so 
        // we don't have to check if it's in there for each validation.
        adminErrors[index] = {
            userFirstName: [],
            userLastName: [],
            userEmail: [],
            userPhone: []
        }

        // Empty field checks:
        if (admin["userFirstName"] === "") adminErrors[index]["userFirstName"].push("Field required.");
        if (admin["userLastName"] === "") adminErrors[index]["userLastName"].push("Field required.");
        if (admin["userEmail"] === "") adminErrors[index]["userEmail"].push("Field required.");

        // Length checks:
        if (admin["userFirstName"].length > 50) adminErrors[index]["userFirstName"].push("First name must be 50 characters or less.");
        if (admin["userLastName"].length > 50) adminErrors[index]["userLastName"].push("Last name must be 50 characters or less.");
        if (admin["userEmail"].length > 255) adminErrors[index]["userEmail"].push("Email must be 255 characters or less.");
        if (admin["userPhone"].length > 50) adminErrors[index]["userPhone"].push("Phone must be 50 characters or less.");

        // Regex:
        if (admin["userEmail"] && !/^[^@]+@[^@]+\.[a-zA-Z.]+$/.test(admin["userEmail"])) adminErrors[index]["userEmail"].push("Invalid email.");

        // Unique check:
        if (!uniqueEmail(admin["userEmail"], emailCounts)) adminErrors[index]["userEmail"].push("Email for each user must be unique.");

        // Remove the empty error fields.
        for (const [field, errors] of Object.entries(adminErrors[index])) {
            if (errors.length < 1) delete adminErrors[index][field];
        }
    })

    // Remove admins from the admin errors if they don't have any errors.
    for (const [index, fields] of Object.entries(adminErrors)) {
        if (Object.keys(fields).length < 1) delete adminErrors[index];
    }

    return adminErrors;
}

export const preValidateTesters = (testerInfo, adminInfo) => {
    const testerErrors = {};

    // Email counts are produced upon preValidation so that's why
    // we need to receive adminInfo and testerInfo here.
    const emailCounts = countEmails(adminInfo, testerInfo);

    testerInfo.forEach((tester, index) => {
        // Temporarily add this tester's fields to errors, so 
        // we don't have to check if it's in there for each validation.
        testerErrors[index] = {
            userFirstName: [],
            userLastName: [],
            userEmail: [],
            userPhone: []
        }

        // Empty field checks:
        if (tester["userFirstName"] === "") testerErrors[index]["userFirstName"].push("Field required.");
        if (tester["userLastName"] === "") testerErrors[index]["userLastName"].push("Field required.");
        if (tester["userEmail"] === "") testerErrors[index]["userEmail"].push("Field required.");

        // Length checks:
        if (tester["userFirstName"].length > 50) testerErrors[index]["userFirstName"].push("First name must be 50 characters or less.");
        if (tester["userLastName"].length > 50) testerErrors[index]["userLastName"].push("Last name must be 50 characters or less.");
        if (tester["userEmail"].length > 255) testerErrors[index]["userEmail"].push("Email must be 255 characters or less.");
        if (tester["userPhone"].length > 50) testerErrors[index]["userPhone"].push("Phone must be 50 characters or less.");

        // Regex:
        if (tester["userEmail"] && !/^[^@]+@[^@]+\.[a-zA-Z.]+$/.test(tester["userEmail"])) testerErrors[index]["userEmail"].push("Invalid email.");

        // Unique check:
        if (!uniqueEmail(tester["userEmail"], emailCounts)) testerErrors[index]["userEmail"].push("Email for each user must be unique.");

        // Remove the empty error fields.
        for (const [field, errors] of Object.entries(testerErrors[index])) {
            if (errors.length < 1) delete testerErrors[index][field];
        }
    })

    // Remove tester from the tester errors if they don't have any errors.
    for (const [index, fields] of Object.entries(testerErrors)) {
        if (Object.keys(fields).length < 1) delete testerErrors[index];
    }

    return testerErrors;
}

export const preValidateCerts = (testerInfo) => {
    const certErrors = {};

    testerInfo.forEach((tester, testerIdx) => {
        // Temporarily add an error key for this tester.
        certErrors[testerIdx] = {};

        tester["userCerts"].forEach((cert, certIdx) => {
            // Temporarily add an error key for this cert.
            certErrors[testerIdx][certIdx] = {
                certNum: [],
                certExp: [],
                certAgency: [],
                certType: [],
                certDoc: [],
            };

            // Empty field checks:
            if (cert["certNum"] === "") certErrors[testerIdx][certIdx]["certNum"].push("Field required.");
            if (cert["certExp"] === "") certErrors[testerIdx][certIdx]["certExp"].push("Field required.");
            if (cert["certAgency"] === "") certErrors[testerIdx][certIdx]["certAgency"].push("Field required.");
            if (cert["certType"] === "") certErrors[testerIdx][certIdx]["certType"].push("Field required.");
            if (cert["certDocLink"] === "") certErrors[testerIdx][certIdx]["certDoc"].push("Field required.");

            // Length checks:
            if (cert["certNum"].length > 255) certErrors[testerIdx][certIdx]["certNum"].push("Certification number must be 255 characters or less.");

            // Remove the empty error fields.
            for (const [field, errors] of Object.entries(certErrors[testerIdx][certIdx])) {
                if (errors.length < 1) delete certErrors[testerIdx][certIdx][field];
            }
        })

        // Remove cert from the cert errors if they don't have any errors.
        for (const [certIdx, fields] of Object.entries(certErrors[testerIdx])) {
            if (Object.keys(fields).length < 1) delete certErrors[testerIdx][certIdx];
        }
    })

    // Remove tester from the cert errors if they don't have any errors.
    for (const [testerIdx, certs] of Object.entries(certErrors)) {
        if (Object.keys(certs).length < 1) delete certErrors[testerIdx];
    }

    return certErrors;
}

export const preValidateTestKits = (testKitInfo) => {
    const testKitErrors = {};

    testKitInfo.forEach((testKit, index) => {
        // Temporarily add this test kit's fields to errors, so 
        // we don't have to check if it's in there for each validation.
        testKitErrors[index] = {
            kitSerial: [],
            kitMfr: [],
            kitModel: [],
            kitCalibDate: [],
            kitCalibDoc: []
        }

        // Empty field checks:
        if (testKit["kitSerial"] === "") testKitErrors[index]["kitSerial"].push("Field required.");
        if (testKit["kitMfr"] === "") testKitErrors[index]["kitMfr"].push("Field required.");
        if (testKit["kitModel"] === "") testKitErrors[index]["kitModel"].push("Field required.");
        if (testKit["kitCalibDate"] === "") testKitErrors[index]["kitCalibDate"].push("Field required.");
        if (testKit["kitCalibDocLink"] === "") testKitErrors[index]["kitCalibDoc"].push("Field required.");

        // Length checks:
        if (testKit["kitSerial"].length > 255) testKitErrors[index]["kitSerial"].push("Serial number must be 255 characters or less.");
        if (testKit["kitMfr"].length > 255) testKitErrors[index]["kitMfr"].push("Manufacturer must be 255 characters or less.");
        if (testKit["kitModel"].length > 255) testKitErrors[index]["kitModel"].push("Model must be 255 characters or less.");

        // Remove the empty error fields.
        for (const [field, errors] of Object.entries(testKitErrors[index])) {
            if (errors.length < 1) delete testKitErrors[index][field];
        }
    })

    // Remove test kit from the test kit errors if they don't have any errors.
    for (const [index, fields] of Object.entries(testKitErrors)) {
        if (Object.keys(fields).length < 1) delete testKitErrors[index];
    }

    return testKitErrors;
}

export const preValidateReport = (payload, validOrganizations, binaryOptions) => {
    const errors = [];

    // If "An Organization" is selected:
    // - "Search Organization" must contain a valid organization.
    if (binaryOptions["reportFor"] === "An Organization") {
        if (!validOrganizations[payload["orgId"]]) {
            errors.push("Please select an organization.");
        }
    }

    // If "Date Range" is selected:
    // - "Range Start Date" and "Range End Date" must contain dates.
    // - "Range Start Date" must not be after "Range End Date".
    // - "Range End Date" amd "Range Start Date" cannot be in the future.
    if (binaryOptions["range"] === "Date Range") {
        if (payload["rangeStart"] === "") {
            errors.push("Please enter a range start date.");
        }
        if (payload["rangeEnd"] === "") {
            errors.push("Please enter a range end date.");
        }
        if (payload["rangeStart"] > payload["rangeEnd"]) {
            errors.push("Date range end must be after date range start.");
        }
        const currentDate = new Date().toISOString().slice(0, 10);
        if (payload["rangeEnd"] > currentDate || payload["rangeStart"] > currentDate) {
            errors.push("Date range cannot be in the future.");
        }
    }

    // If "Delivery Method" is selected:
    // - "Recipient Email" must contain an email.
    if (binaryOptions["deliveryMethod"] === "Email") {
        if (payload["recipientEmail"] === "") {
            errors.push("Please enter an email.");
        }
        if (payload["recipientEmail"] && !/^[^@]+@[^@]+\.[a-zA-Z.]+$/.test(payload["recipientEmail"])) {
            errors.push("Please enter a valid email.");
        }
    }

    // A value for "Report Type" must be selected.
    if (payload["reportType"] === "") {
        errors.push("Please select a report type.");
    }

    return errors;
}

export const preValidateRequestUsers = (limit, orgId, query) => {
    const errors = [];

    // If the limit is set to "An Organization" we must verify that a valid org ID is selected.
    if (limit === "An Organization" && orgId === "") errors.push("Please select a valid organization.");

    // If the query is not empty, we must verify its length.
    if (query !== "" && query.length > 255) errors.push("Name must be 255 characters or less.");

    return errors;
}

export const preValidateEditUser = (payload) => {
    const errors = [];

    // Empty field checks:
    if (payload.userType === "") errors.push("User Type is required.");
    if (payload.userFirstName === "") errors.push("First Name is required.");
    if (payload.userLastName === "") errors.push("Last Name is required.");
    if (payload.userEmail === "") errors.push("Email is required.");

    // Length checks:
    if (payload.userFirstName.length > 50) errors.push("First Name must be 50 characters or less.");
    if (payload.userLastName.length > 50) errors.push("Last Name must be 50 characters or less.");
    if (payload.userEmail.length > 255) errors.push("Email must be 255 characters or less.");
    if (payload.userPhone.length > 50) errors.push("Phone must be 50 characters or less.");

    // Regex:
    if (payload.userEmail && !/^[^@]+@[^@]+\.[a-zA-Z.]+$/.test(payload.userEmail)) errors.push("Invalid email.");

    return errors;
}

export const preValidateCreateOrg = (payload) => {
    const errors = [];

    // Empty field checks:
    if (payload.orgURL === "") errors.push("Organization Name is required.");
    if (payload.orgEndDate === "") errors.push("Allowed Access Until is required.");
    if (payload.numOfCerts < 1) errors.push("Required Certifications must be at least 1.");

    return errors;
}

export const preValidateEditOrg = (payload) => {
    const errors = [];

    // Empty field checks:
    if (payload.orgEndDate === "") errors.push("Allowed Access Until is required.");
    if (payload.numOfCerts < 1) errors.push("Required Certifications must be at least 1.");

    return errors;
}