import React, { useEffect, useState } from "react";
import SearchSelect from "../searchSelect";
import { getOrgs, getSignupCount } from "../../utils/requests";
import APIService from "../../APIService";
import { preValidateReport } from "../../utils/preValidations";
import { useToken } from "../../TokenContext";

const Reports = () => {
  const { currentToken } = useToken();
  const [errors, setErrors] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [organizations, setOrganizations] = useState({});
  const [orgQueryCheck, setOrgQueryCheck] = useState(false);
  const [totalSignUps, setTotalSignUps] = useState("N/A");

  const [reportFor, setReportFor] = useState("An Organization");
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [range, setRange] = useState("All Time");
  const [rangeStart, setRangeStart] = useState("");
  const [rangeEnd, setRangeEnd] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("Download");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [reportType, setReportType] = useState("");


  const handleReportFor = (selection) => {
    if (selection === "An Organization") {
      setReportFor("An Organization");
    } else {
      setReportFor("All Organizations");
      setSelectedOrgId("");
      setTotalSignUps("N/A");
      setRange("Date Range")
      setReportType("")
    }
  };

  const handleGenerateReport = async () => {
    setLoading(true);
    setErrors([]);

    if (reportFor === "An Organization" && !orgQueryCheck) {
      setErrors(["Please select a valid organization."]);
      document.getElementsByClassName("page")[0].scrollTo(0, 0);
      setLoading(false);
      return;
    }

    let orgId = selectedOrgId !== "" ? selectedOrgId : "All";
    let orgName = selectedOrgId !== "" ? organizations[orgId] : "All";

    const payload = {
      orgId: orgId,
      orgName: orgName,
      range: range,
      rangeStart: rangeStart,
      rangeEnd: rangeEnd,
      deliveryMethod: deliveryMethod,
      recipientEmail: recipientEmail,
      reportType: reportType,
    };

    const binaryOptions = {
      reportFor: reportFor,
      range: range,
      deliveryMethod: deliveryMethod,
    };

    // Run pre-validations.
    const preValidationErrors = preValidateReport(
      payload,
      organizations,
      binaryOptions
    );

    if (preValidationErrors.length > 0) {
      setErrors(preValidationErrors);
      setLoading(false);
      return;
    }

    // Since we are reading the API response directly here, we need to make
    // the APIService call here.
    let response;
    try {
      response = await APIService.getOrgReport(payload, currentToken);
    } catch (error) {
      console.log(error);
      setErrors(["API fetch failed."]);
      setLoading(false);
      return;
    }

    if (!response.ok) {
      console.log("API response was not OK.");
      setErrors(["API response was not OK."]);
      setLoading(false);
      return;
    }

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") !== -1) {
      const json = await response.json();
      console.log(json);
      if (json && json.message) {
        setErrors([json.message]);
        setLoading(false);
        return;
      } else if (json && json.emailMessage) {
        setResponseMessage(json.emailMessage);
        setLoading(false);
        return;
      }
    }

    const blob = await response.blob(); // Convert the response data to a blob
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    let file = "";
    if (reportType === "Users") {
      file = "testers.csv";
    } else if (reportType === "Service Providers") {
      file = "service_providers.csv";
    } else if (reportType === "Test Kits") {
      file = "service_provider_test_kits.csv";
    } else if (reportType === "Implementation Integration Files") {
      file = "ie_bundle.zip";
    }

    // replacing white spaces and special characters when generating a file
    a.download = `${orgName
      .replaceAll(" ", "")
      .replace(/[^a-zA-Z0-9]/g, "")}_${file}`;
    document.body.appendChild(a);
    a.click();

    setLoading(false);
  };

  const getOrgSignUps = async (id) => {
    const getSignUpsRes = await getSignupCount(id, currentToken);
    if (getSignUpsRes.code === "SUCCESS") {
      setTotalSignUps(String(getSignUpsRes.message));
    } else {
      console.log(getSignUpsRes.code);
      setErrors([getSignUpsRes.message]);
    }
  };

  const getToday = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  const getOneWeekAgo = () => {
    const today = new Date();
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);
    return oneWeekAgo.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  const minDate = reportFor === "All Organizations" ? getOneWeekAgo() : "";
  const maxDate = getToday();

  useEffect(() => {
    const requestInfo = async () => {
      const getOrgsRes = await getOrgs(currentToken);
      if (getOrgsRes.code === "SUCCESS") {
        setOrganizations(getOrgsRes.message);
      } else {
        console.log(getOrgsRes.code);
        setErrors([getOrgsRes.message]);
      }
    };

    requestInfo();
  }, []);

  useEffect(() => {
    if (responseMessage) {
      const timeoutId = setTimeout(() => {
        setResponseMessage("");
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [responseMessage]);

  useEffect(
    () => document.getElementsByClassName("page")[0].scrollTo(0, 0),
    [errors]
  );

  return (
    <form className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="section">
        <div className="sub-heading">Reports</div>
        <div className="group">
          {errors?.length > 0 && (
            <div className="row">
              <div className="field">
                <ul className="errors">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="row">
            <label className="field">
              Report For
              <div className="toggle" style={{ maxWidth: "25rem" }}>
                <button
                  className={
                    reportFor === "An Organization"
                      ? "option left selected"
                      : "option left"
                  }
                  onClick={() => handleReportFor("An Organization")}
                >
                  An Organization
                </button>
                <button
                  className={
                    reportFor === "All Organizations"
                      ? "option right selected"
                      : "option right"
                  }
                  onClick={() => handleReportFor("All Organizations")}
                >
                  All Organizations
                </button>
              </div>
            </label>

            {reportFor === "An Organization" && (
              <>
                <label className="field">
                  Total Registrations
                  <input value={totalSignUps} disabled={true} />
                </label>

                <div className="field spacer" />
              </>
            )}
          </div>

          {reportFor === "An Organization" && (
            <div className="row">
              <label className="field">
                Search Organization
                <SearchSelect
                  options={organizations}
                  setFunction={setSelectedOrgId}
                  additionalFunction={getOrgSignUps}
                  setCheckFunction={setOrgQueryCheck}
                />
              </label>
            </div>
          )}

          <div className="row">
            <label className="field">
              Report Range
              <div className="toggle" style={{ maxWidth: "25rem" }}>
                <button
                  className={
                    range === "All Time"
                      ? "option left selected"
                      : "option left"
                  }
                  onClick={() => setRange("All Time")}
                  disabled={reportFor === "All Organizations"}
                >
                  All Time
                </button>
                <button
                  className={
                    range === "Date Range"
                      ? "option right selected"
                      : "option right"
                  }
                  onClick={() => setRange("Date Range")}
                >
                  Date Range
                </button>
              </div>
            </label>

            {range === "Date Range" && (
              <>
                <label className="field">
                  Range Start Date
                  <input
                    type="date"
                    name="rangeStartDate"
                    value={rangeStart}
                    onChange={(e) => setRangeStart(e.target.value)}
                    min={minDate}
                    max={maxDate}
                  />
                </label>

                <label className="field">
                  Range End Date
                  <input
                    type="date"
                    name="rangeEndDate"
                    value={rangeEnd}
                    onChange={(e) => setRangeEnd(e.target.value)}
                    min={minDate}
                    max={maxDate}
                  />
                </label>
              </>
            )}
          </div>

          <div className="row">
            <label className="field">
              Delivery Method
              <div className="toggle" style={{ maxWidth: "25rem" }}>
                <button
                  className={
                    deliveryMethod === "Download"
                      ? "option left selected"
                      : "option left"
                  }
                  onClick={() => setDeliveryMethod("Download")}
                >
                  Download
                </button>
                <button
                  className={
                    deliveryMethod === "Email"
                      ? "option right selected"
                      : "option right"
                  }
                  onClick={() => setDeliveryMethod("Email")}
                >
                  Email
                </button>
              </div>
            </label>

            {deliveryMethod === "Email" && (
              <>
                <label className="field">
                  Recipient Email
                  <input
                    name="recipientEmail"
                    value={recipientEmail}
                    onChange={(e) => setRecipientEmail(e.target.value)}
                  />
                </label>
                <div className="field spacer" />
              </>
            )}
          </div>

          <div className="row">
            <label className="field">
              Report Type
              <select
                type="select"
                name="reportType"
                style={{ maxWidth: "25rem" }}
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
              >
                <option value="">Select...</option>
                <option value="Users">Users</option>
                <option
                  value="Service Providers"
                  disabled={reportFor === "All Organizations"}
                >
                  Service Providers
                </option>
                <option value="Test Kits">Test Kits</option>
                <option
                  value="Implementation Integration Files"
                  disabled={reportFor === "All Organizations"}
                >
                  Implementation Integration Files
                </option>
              </select>
            </label>
            <div className="field-button">
              <button
                className="button green"
                type="button"
                disabled={loading}
                onClick={() => handleGenerateReport()}
              >
                {loading ? "Loading..." : "Generate Report"}
              </button>
            </div>
            <div className="field spacer" />
          </div>

          {responseMessage && (
            <div className="row">
              <div className="field">{responseMessage}</div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
}

export default Reports;
