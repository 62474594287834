import React, { useState } from "react";
import _ from "lodash";

import APIService from "../APIService";
import { getErrorMessages } from "../utils/errors";
import Tooltip from "./tooltip";

const Tester = ({ testerInfo, handleTesterEntry, removeTester, orgCerts, handleCertEntry, testerErrors, certErrors, addCert, removeCert, requiredNumOfCerts, setTesterInfo, setTesterErrors, setCertErrors, orgHash }) => {
    const [uploading, setUploading] = useState("-1, -1");
    const testerErrorMessages = getErrorMessages(testerErrors);
    const testerCertErrorMessages = {};

    if (Object.keys(certErrors).length > 0) {
        for (let tester in certErrors) {
            testerCertErrorMessages[tester] = getErrorMessages(certErrors[tester]);
        }
    }

    const handleDocUpload = async (testerIdx, certIdx) => {
        setUploading(`${testerIdx}, ${certIdx}`);

        // Grab the file from the input.
        const docData = testerInfo[testerIdx]["userCerts"][certIdx].certDoc;

        // Holding place for file errors:
        const fileErrors = [];

        // Perform PreValidations:
        if (docData.size > 10000000) {
            // File size measured in bytes.
            fileErrors.push("File must be under 10mb.");
        }

        if (!["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(docData.type)) {
            fileErrors.push("File type not supported.");
        }

        // If PreValidations produced errors, set the error state, and do not attempt file upload.
        if (fileErrors.length > 0) {
            const newCertErrors = _.cloneDeep(certErrors);

            if (newCertErrors[testerIdx]) {
                // Errors for the tester exist.
                if (newCertErrors[testerIdx][certIdx]) {
                    // Errors for the tester and cert exist.
                    newCertErrors[testerIdx][certIdx].certDoc = fileErrors;
                } else {
                    // Errors for the tester exist but errors for the cert don't exist.
                    newCertErrors[testerIdx][certIdx] = { "certDoc": fileErrors };
                }
            } else {
                // Errors for the tester and cert don't exist.
                newCertErrors[testerIdx] = {};
                newCertErrors[testerIdx][certIdx] = { "certDoc": fileErrors };
            }

            setCertErrors(newCertErrors);

            setUploading("-1, -1");
            return;
        }

        // Attempt file upload.
        const res = await APIService.uploadDoc(docData, orgHash);

        if (res.code === "SUCCESS") {
            // Deep copy testerInfo and set the doc link for this specific cert.
            const newTesterInfo = _.cloneDeep(testerInfo);
            newTesterInfo[testerIdx]["userCerts"][certIdx].certDocLink = res.message.url;
            setTesterInfo(newTesterInfo);
        } else {
            // Set the errors for this cert to reflect a file upload problem.
            const newCertErrors = _.cloneDeep(certErrors);
            const uploadErrorMsg = ["There was a problem uploading this file."];

            if (newCertErrors[testerIdx]) {
                // Errors for the tester exist.
                if (newCertErrors[testerIdx][certIdx]) {
                    // Errors for the tester and cert exist.
                    newCertErrors[testerIdx][certIdx].certDoc = uploadErrorMsg;
                } else {
                    // Errors for the tester exist but errors for the cert don't exist.
                    newCertErrors[testerIdx][certIdx] = { "certDoc": uploadErrorMsg };
                }
            } else {
                // Errors for the tester and cert don't exist.
                newCertErrors[testerIdx] = {};
                newCertErrors[testerIdx][certIdx] = { "certDoc": uploadErrorMsg };
            }

            setCertErrors(newCertErrors);
        }

        setUploading("-1, -1");
    }

    return (
        <>
            {testerInfo.map((tester, testerIdx) => {
                return (
                    <div className="section" key={testerIdx}>
                        <div className="sub-heading">Tester #{testerIdx + 1}</div>
                        <div className="group">

                            {testerErrorMessages[testerIdx]?.length > 0 && (
                                <div className="row">
                                    <div className="field">
                                        <ul className="errors">
                                            {testerErrorMessages[testerIdx].map((error, errorIdx) => <li key={errorIdx}>{error}</li>)}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <label className={testerErrors[testerIdx]?.userFirstName ? "field error-highlight" : "field"}>
                                    First Name*
                                    <input
                                        name="userFirstName"
                                        required={true}
                                        value={tester.userFirstName}
                                        onChange={(event) => handleTesterEntry(testerIdx, event)}
                                    />
                                </label>
                                <label className={testerErrors[testerIdx]?.userLastName ? "field error-highlight" : "field"}>
                                    Last Name*
                                    <input
                                        name="userLastName"
                                        value={tester.userLastName}
                                        onChange={(event) => handleTesterEntry(testerIdx, event)}
                                    />
                                </label>
                            </div>

                            <div className="row">
                                <label className={testerErrors[testerIdx]?.userEmail ? "field error-highlight" : "field"}>
                                    <div className="label">
                                        Email*
                                        <Tooltip text={"This must be a unique, individual email address. A unique email address is required for each tester or admin being registered."} />
                                    </div>
                                    <input
                                        name="userEmail"
                                        value={tester.userEmail}
                                        onChange={(event) => handleTesterEntry(testerIdx, event)}
                                    />
                                </label>
                                <label className={testerErrors[testerIdx]?.userPhone ? "field error-highlight" : "field"}>
                                    Phone
                                    <input
                                        name="userPhone"
                                        value={tester.userPhone}
                                        onChange={(event) => handleTesterEntry(testerIdx, event)}
                                    />
                                </label>
                            </div>

                            {testerInfo[testerIdx].userCerts.map((cert, certIdx) => {
                                return (
                                    <div className="subgroup" key={certIdx}>
                                        <div className="divider" />
                                        <div className="row">
                                            <div className="field">
                                                <div className="label">
                                                    <strong>Certification #{certIdx + 1}{certIdx < requiredNumOfCerts ? "*" : ""}</strong>
                                                    <Tooltip text={"To submit test reports through SwiftComply, a current and valid backflow assembly testing certification is required."} />
                                                </div>
                                            </div>
                                        </div>

                                        {testerCertErrorMessages[testerIdx] && testerCertErrorMessages[testerIdx][certIdx]?.length > 0 && (
                                            <div className="row">
                                                <div className="field">
                                                    <ul className="errors">
                                                        {testerCertErrorMessages[testerIdx][certIdx].map((error, errorIdx) => <li key={errorIdx}>{error}</li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}

                                        <div className="row">
                                            <label className={certErrors[testerIdx] && certErrors[testerIdx][certIdx]?.certNum ? "field error-highlight" : "field"}>
                                                Number*
                                                <input
                                                    name="certNum"
                                                    value={cert.certNum}
                                                    onChange={(event) => handleCertEntry(testerIdx, certIdx, event)}
                                                />
                                            </label>
                                            <label className={certErrors[testerIdx] && certErrors[testerIdx][certIdx]?.certExp ? "field error-highlight" : "field"}>
                                                Expiration*
                                                <input
                                                    type="date"
                                                    name="certExp"
                                                    value={cert.certExp}
                                                    onChange={(event) => handleCertEntry(testerIdx, certIdx, event)}
                                                />
                                            </label>
                                        </div>

                                        <div className="row">
                                            <label className={certErrors[testerIdx] && certErrors[testerIdx][certIdx]?.certAgency ? "field error-highlight" : "field"}>
                                                Agency*
                                                <select
                                                    type="select"
                                                    name="certAgency"
                                                    value={cert.certAgency}
                                                    onChange={(event) => handleCertEntry(testerIdx, certIdx, event)}
                                                >
                                                    <option value="">Please select an agency</option>
                                                    {Object.keys(orgCerts).map((agencyId) => {
                                                        return (
                                                            <option value={agencyId} key={agencyId}>{orgCerts[agencyId].agency}</option>
                                                        );
                                                    })}
                                                </select>
                                            </label>
                                            <label className={certErrors[testerIdx] && certErrors[testerIdx][certIdx]?.certType ? "field error-highlight" : "field"}>
                                                Type*
                                                <select
                                                    disabled={cert.certAgency === ""}
                                                    type="select"
                                                    name="certType"
                                                    value={cert.certType}
                                                    onChange={(event) => handleCertEntry(testerIdx, certIdx, event)}
                                                >
                                                    <option value="">Please select a type</option>
                                                    {orgCerts[cert.certAgency] && Object.keys(orgCerts[cert.certAgency].associatedTypes).map((typeId) => {
                                                        return (
                                                            <option value={typeId} key={typeId}>{orgCerts[cert.certAgency].associatedTypes[typeId]}</option>
                                                        );
                                                    })}
                                                </select>
                                            </label>
                                        </div>

                                        <div className="row">
                                            <div className={certErrors[testerIdx] && certErrors[testerIdx][certIdx]?.certDoc ? "field error-highlight" : "field"}>
                                                <div className="label">
                                                    Document*
                                                    <Tooltip text={"Please attach a copy of your backflow assembly testing certification document. After you choose a file, click the upload button to submit the file."} />
                                                </div>
                                                <div className="file-input">
                                                    <div className="row">
                                                        <label className="button-file">
                                                            Choose a file...
                                                            <input
                                                                type="file"
                                                                accept="image/jpg, image/jpeg, image/png, application/pdf"
                                                                name="certDoc"
                                                                onChange={(event) => handleCertEntry(testerIdx, certIdx, event)}
                                                            />
                                                        </label>
                                                        <div className="print-hidden" style={{ fontSize: 1 + "rem" }}>.jpg, .jpeg, .pdf, .png (under 10mb)</div>
                                                    </div>
                                                    <div className="row">
                                                        <button
                                                            type="button"
                                                            className={testerInfo[testerIdx]["userCerts"][certIdx].certDocLink === "" && testerInfo[testerIdx]["userCerts"][certIdx].certDoc ? "button green" : "button"}
                                                            onClick={() => handleDocUpload(testerIdx, certIdx)}
                                                            disabled={uploading === `${testerIdx}, ${certIdx}` ? true : testerInfo[testerIdx]["userCerts"][certIdx].certDocLink === "" && testerInfo[testerIdx]["userCerts"][certIdx].certDoc ? false : true}
                                                        >
                                                            {uploading === `${testerIdx}, ${certIdx}` ? "Uploading..." : testerInfo[testerIdx]["userCerts"][certIdx].certDocLink === "" ? "Upload" : "Uploaded"}
                                                        </button>
                                                        {testerInfo[testerIdx]["userCerts"][certIdx].certDoc &&
                                                            <div>{testerInfo[testerIdx]["userCerts"][certIdx].certDoc.name}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {certIdx >= requiredNumOfCerts && (
                                            <button type="button" onClick={() => removeCert(testerIdx, certIdx, requiredNumOfCerts)} className="button">Remove Cert #{certIdx + 1}</button>
                                        )}
                                    </div>
                                )
                            })}

                            <div className="divider" />

                            <div className="row" style={{ justifyContent: "flex-start" }}>
                                <button type="button" onClick={() => addCert(testerIdx)} className="button">Add Another Cert</button>
                                {testerIdx > 0 && (
                                    <button type="button" onClick={() => removeTester(testerIdx)} className="button">Remove This Tester</button>
                                )}
                            </div>

                        </div>
                    </div>
                );
            })}
        </>
    )
}


export default Tester;
