import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SCAdmin_Auth.css'
import APIService from '../../APIService';


const LogoutButton = ({ onLogoutSuccess }) => {
  const navigateURL = useNavigate(); // Get the history object from react-router-dom

  const handleLogoutClick = () => {
    sessionStorage.removeItem("authToken");
    onLogoutSuccess();
    navigateURL("/scadmin"); // Redirect to /scadmin after logout
  };

  return (
    <div>
      <button className="button" type="submit" onClick={handleLogoutClick}>Log Out</button>
    </div>
  );
};

export default LogoutButton;
