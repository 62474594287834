// Return a deep copy of an error object, with errors of a specific field removed.
export const removeError = (targetIdx, fieldName, oldErrors) => {
    let newErrors = {}

    for (const [index, fields] of Object.entries(oldErrors)) {
        newErrors[index] = {};

        for (const [field, messages] of Object.entries(fields)) {
            newErrors[index][field] = [...messages];
        }

        if (index === targetIdx) {
            delete newErrors[index][fieldName];
        }
    }

    return newErrors;
}

// Take an object of errors, and return a list of error messages 
// (decoupled from their associated fields).
export const getErrorMessages = (rawErrors) => {
    const errorMessages = {};

    for (const [index, fields] of Object.entries(rawErrors)) {
        for (const messages of Object.values(fields)) {
            for (const message of messages) {
                // Skip "Field required." messages.
                if (message === "Field required.") continue;
                errorMessages[index] ? errorMessages[index].push(message) : errorMessages[index] = [message];
            }
        }
    }

    return errorMessages;
}