import React from "react";

const OrgInfoError = ({ orgInfoErrorCode }) => {

    let errorMessage = "";

    switch (orgInfoErrorCode) {
        case "ORG URL INVALID":
            errorMessage = "The link you are attempting to access does not exist. Please ensure you have copied it correctly.";
            break;
        case "ORG URL EXPIRED":
            errorMessage = "The registration form you are attempting to access is no longer available. Please reach out to the organization you received this link from if you believe this is an error.";
            break;
        default:
            errorMessage = "Invalid URL."
    }


    return (
        <div className="section">
            <div className="group">
                <div className="row">
                    <p>
                        <strong>{errorMessage}</strong>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default OrgInfoError;