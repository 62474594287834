import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import APIService from "../../APIService";
import { useToken } from "../../TokenContext";

const InactiveOrganizations = () => {
    const { currentToken } = useToken();
    const [orgName, setOrgName] = useState("");
    const [results, setResults] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const res = await APIService.getOrgByStatus("inactive", currentToken);
 
            if (res.code == "INVALID" || res.code == "EXPIRED") {
              setError(res.message);
            } else {
              setResults(res);
            }
        }
        fetchData();
    }, []);

    const handleSearch = (e) => {
        setOrgName(e.target.value)
    };

    const filteredData = results.filter((item) =>
        String(item.name).toLowerCase().split(' ').some(word => word.startsWith(orgName.toLowerCase()))
    );

    filteredData.forEach((item) => {
      if (item.created_on) {
        const createdOnDateString = item.created_on;
        const allowedUntilDateString = item.allowed_access;

        const createdDate = new Date(createdOnDateString);
        item.created_on = createdDate.toISOString().substring(0, 10);

        const allowedDate = new Date(allowedUntilDateString);
        item.allowed_access = allowedDate.toISOString().substring(0, 10);
      }
    });


    return (
      <form className="form">
        <div className="section">
          <div className="sub-heading">Inactive Organizations</div>
          <div className="group">
            <div className="row">
              <label className="field">
                Search Inactive Organizations
                <input
                  required={true}
                  value={orgName}
                  onChange={(e) => handleSearch(e)}
                />
              </label>
            </div>

            {error && <div className="error">{error}</div>}

            <div
              className="table-container-row"
              style={{ height: "fit-content" }}
            >
              <table cellPadding="0" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th style={{ textAlign: "center" }}>Created On</th>
                    <th style={{ textAlign: "center" }}>Access Expired On</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {(orgName === "" ? results : filteredData).map((org) => (
                    <tr key={org.id}>
                      <td>{org.name}</td>
                      <td style={{ textAlign: "center" }}>{org.created_on}</td>
                      <td style={{ textAlign: "center" }}>
                        {org.allowed_access}
                      </td>
                      <td>
                        <div className="link">
                          <Link
                            key={org.id}
                            to={`/scadmin/vieworedit/${encodeURIComponent(
                              org.name
                            )}`}
                          >
                            View
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    );
}

export default InactiveOrganizations;
