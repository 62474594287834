import React from 'react';
import "../styles/CSS/base.css";

const OrgHomeHeader = ({ orgName }) => {
    return (
      <div className="header-org-home">
        <div>
          <a href="https://www.sc-tester.com/scadmin">
          <img
            src="../InfinityLogo_white.png"
            alt="SwiftComply"
            className="logo"
          />
          </a>
          <h1>SwiftComply</h1>
        </div>
        <div>
          <h2>{orgName}</h2>
        </div>
      </div>
    );
};

export default OrgHomeHeader;