import React, { useEffect, useState } from "react";

import { getAllCertificationTypes, getAllCertifications } from "../../utils/requests";
import { submitDeleteAgency, submitDeleteType, submitNewAgency, submitNewType, submitTypeAssociation } from "../../utils/submissions";
import { useToken } from "../../TokenContext";

const Certifications = () => {
    const { currentToken } = useToken();
    const [allCerts, setAllCerts] = useState({});
    const [allAgenciesArray, setAllAgenciesArray] = useState([]);
    const [allTypesArray, setAllTypesArray] = useState([]);
    const [errors, setErrors] = useState([]);
    const [selectedAgencyId, setSelectedAgencyId] = useState("");
    const [newAgency, setNewAgency] = useState("");
    const [newType, setNewType] = useState("");
    const [loading, setLoading] = useState(false);

    const formatAgencies = (rawCerts) => {
        setAllAgenciesArray(Object.entries(rawCerts).map((pair) => ({agencyId: pair[0], agencyName: pair[1].agency})).sort((a, b) => {
            if (a.agencyName.toLowerCase() < b.agencyName.toLowerCase()) {
                return -1;
            }
            if (a.agencyName.toLowerCase() > b.agencyName.toLowerCase()) {
                return 1;
            }
            return 0;
        }))
    }

    const formatTypes = (rawTypes) => {
        setAllTypesArray(Object.entries(rawTypes).map((pair) => ({typeId: pair[0], typeName: pair[1]})).sort((a, b) => {
            if (a.typeName.toLowerCase() < b.typeName.toLowerCase()) {
                return -1;
            }
            if (a.typeName.toLowerCase() > b.typeName.toLowerCase()) {
                return 1;
            }
            return 0;
        }))
    }

    const requestInfo = async () => {
        setLoading(true);

        const getCertsRes = await getAllCertifications(currentToken);

        if (getCertsRes.code === "SUCCESS") {
            setAllCerts(getCertsRes.message);
            formatAgencies(getCertsRes.message);
        } else {
            setErrors(prevErrors => [...prevErrors, [getCertsRes.message]]);
        }

        const getTypesRes = await getAllCertificationTypes(currentToken);

        if (getTypesRes.code === "SUCCESS") {
            formatTypes(getTypesRes.message);
        } else {
            setErrors(prevErrors => [...prevErrors, [getTypesRes.message]])
        }

        setLoading(false);
    }

    const associateType = async (agencyId, typeId, enabled) => {
        setLoading(true);
        setErrors([]);

        const res = await submitTypeAssociation(agencyId, typeId, enabled, currentToken);

        if (res.code === "SUCCESS") {
            setAllCerts(res.message);
            setLoading(false);
        } else {
            setErrors([res.message])
            setLoading(false);
        }
    }

    const addAgency = async () => {
        setLoading(true);
        setErrors([]);

        const res = await submitNewAgency(newAgency, currentToken);

        if (res.code === "SUCCESS") {
            setSelectedAgencyId("");
            setAllCerts(res.message);
            formatAgencies(res.message);
            setNewAgency("");
            setLoading(false);
        } else {
            setErrors([res.message])
            setLoading(false);
        }
    }

    const deleteAgency = async () => {
        setLoading(true);
        setErrors([]);

        const res = await submitDeleteAgency(newAgency, currentToken);

        if (res.code === "SUCCESS") {
            setSelectedAgencyId("");
            setAllCerts(res.message);
            formatAgencies(res.message);
            setNewAgency("");
            setLoading(false);
        } else {
            setErrors([res.message])
            setLoading(false);
        }
    }

    const addType = async () => {
        setLoading(true);
        setErrors([]);

        const res = await submitNewType(newType, currentToken);

        if (res.code === "SUCCESS") {
            formatTypes(res.message);
            setNewType("");
            setLoading(false);
        } else {
            setErrors([res.message])
            setLoading(false);
        }
    }

    const deleteType = async () => {
        setLoading(true);
        setErrors([]);

        const res = await submitDeleteType(newType, currentToken);

        if (res.code === "SUCCESS") {
            formatTypes(res.message);
            setNewType("");
            setLoading(false);
        } else {
            setErrors([res.message])
            setLoading(false);
        }
    }

    useEffect(() => {
        requestInfo()
    }, [])

    return (
        <div className="form">
            <div className="section">
                <div className="sub-heading">Certifications</div>
                <div className="group">

                    <div>
                        <p className="first-p">
                            <span className="red">WARNING!</span> Changes made on this page affect options presented on <strong>ALL</strong> organization forms (active and inactive). <u>Get approval from your team lead before making any changes here.</u>
                        </p>
                        <p>
                            To add an agency, scroll to the bottom of the <i>Agency</i> table. Enter the name of the new agency, and click <i>Add</i>.
                        </p>
                        <p>
                            To add an type, scroll to the bottom of the <i>Type</i> table. Enter the name of the new type, and click <i>Add</i>.
                        </p>
                        <p className="last-p">
                            To associate or disassociate a type to an agency, first select the agency from the <i>Agency</i> table. When an agency is selected, it will show a green bar, and the checkboxes in the <i>Type</i> table will populate based on whether the type is associated to that agency. Check the box of a type to associate it with the agency, uncheck it to disassociate it with the agency.
                        </p>
                    </div>

                    {errors.length > 0 && (
                        <div className="row">
                            <div className="field">
                                <ul className="errors">
                                    {errors.map((error, errorIdx) => (<li key={errorIdx}>{error}</li>))}
                                </ul>
                            </div>
                        </div>
                    )}

                </div>
            </div>

            <div className="side-by-side-tables">

                <div className="table-container">
                    <table cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Agency</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td>
                                        Loading...
                                    </td>
                                </tr>
                            )}

                                    {allAgenciesArray && allAgenciesArray.map(({agencyId, agencyName}) => {
                                        return (
                                            <tr key={agencyId}>
                                                <td
                                                    className={selectedAgencyId === agencyId ? "clickable selected" : "clickable"}
                                                    onClick={() => setSelectedAgencyId(agencyId)}
                                                >{agencyName}</td>
                                            </tr>
                                        )
                                    })}

                            <tr>
                                <td>
                                    <div className="input-row">
                                        <input
                                            type="text"
                                            value={newAgency}
                                            onChange={(e) => setNewAgency(e.target.value)}
                                            disabled={loading}
                                        />
                                        <button
                                            type="button"
                                            className="button"
                                            disabled={loading || newAgency === ""}
                                            onClick={() => addAgency()}
                                        >
                                            Add
                                        </button>
                                        <button
                                            type="button"
                                            className="button"
                                            disabled={loading || newAgency === ""}
                                            onClick={() => deleteAgency()}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-container">
                    <table cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th colSpan="2">Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td colSpan="2">
                                        Loading...
                                    </td>
                                </tr>
                            )}

                                    {allTypesArray && allTypesArray.map(({typeId, typeName}) => {
                                        return (
                                            <tr key={typeId}>
                                                <td style={{ paddingRight: "0" }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={allCerts[selectedAgencyId]?.associatedTypes[typeId] ? true : false}
                                                        disabled={loading || selectedAgencyId === ""}
                                                        onChange={(e) => associateType(selectedAgencyId, typeId, (allCerts[selectedAgencyId]?.associatedTypes[typeId] ? "true" : "false"))}
                                                    />
                                                </td>
                                                <td>{typeName}</td>
                                            </tr>
                                        )
                                    })}

                            <tr>
                                <td colSpan="2">
                                    <div className="input-row">
                                        <input
                                            type="text"
                                            value={newType}
                                            onChange={(e) => setNewType(e.target.value)}
                                            disabled={loading}
                                        />
                                        <button
                                            type="button"
                                            className="button"
                                            disabled={loading || newType === ""}
                                            onClick={() => addType()}
                                        >
                                            Add
                                        </button>
                                        <button
                                            type="button"
                                            className="button"
                                            disabled={loading || newType === ""}
                                            onClick={() => deleteType()}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default Certifications;