import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCircleQuestion,
  faFaucetDrip,
  faListOl,
  faPenClip,
  faTriangleExclamation,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

const OrgHome = ({ orgInfo, setShowOrgHome, orgInfoErrorCode }) => {
  let errorMessage = "";

  switch (orgInfoErrorCode) {
    case "ORG URL INVALID":
      errorMessage =
        "The link you are attempting to access does not exist. Please ensure you have copied it correctly.";
      break;
    case "ORG URL EXPIRED":
      errorMessage =
        "The registration form you are attempting to access is no longer available. Please reach out to the organization you received this link from if you believe this is an error.";
      break;
    default:
      errorMessage = "Invalid URL.";
  }

  const renderHomePageBanner = () => {
  if (!orgInfo.homePageBanner) {
    return (
      <p>
        Within 48 hours of registering you will receive an email with details of
        how to login and set your password to enter tests online for{" "}
        {orgInfo.org_name}.
      </p>
    );
  }

  // Regular expression to find URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Split the banner text by URLs
  const parts = orgInfo.homePageBanner.split(urlRegex);

  const formatText = (text) => {
    // this will make anything in -b and -b as bold
    text = text.replace(/-b\s*(.*?)\s*-b/g, "<strong>$1</strong>");

    return text;
  };


  return (
    <div>
      {parts.map((part, index) => {
        if (part.match(urlRegex)) {
          // If part is a URL, render it as a clickable link
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </a>
          );
        } else {
          // Otherwise, render the regular text with preserved line breaks
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: formatText(part).replace(/\n/g, '<br>') }}
            />
          );
        }
      })}
    </div>
  );
};

  return (
    <>
      {!orgInfoErrorCode && (
        <div className="org-home">
          <div className="section-white">
            <div className="group-welcome">
              <h2>{orgInfo.org_name} Tester Registration</h2>
              <p>
                Complete registration to request a SwiftComply Backflow user
                account and submit backflow tests online to {orgInfo.org_name}.
              </p>
              <p>
                <button
                  className="button blue"
                  onClick={() => setShowOrgHome(false)}
                >
                  Get Started{" "}
                  <FontAwesomeIcon icon={faArrowRight} className="icon" />
                </button>
              </p>
            </div>

            <div className="group">
              <div className="module-green">
                <FontAwesomeIcon icon={faFaucetDrip} className="icon" />
                <h3>Registration Info</h3>
                {renderHomePageBanner()}
              </div>
              <div className="module-black">
                <FontAwesomeIcon icon={faListOl} className="icon" />
                <h3>Requirements</h3>
                <p>
                  Emails must be unique for both admin and tester users. If you
                  have already submitted this form for another organization, you
                  must submit it again for {orgInfo.org_name}.
                </p>
              </div>
            </div>

            <div className="group">
              <div className="module-purple">
                <FontAwesomeIcon icon={faPenClip} className="icon" />
                <h3>Admin Users</h3>
                <p>
                  Admin users are for service provider administration, within
                  certain configuration settings they can enter test reports on
                  behalf of their service provider testers, no certification
                  information is required.
                </p>
              </div>
              <div className="module-blue">
                <FontAwesomeIcon icon={faWrench} className="icon" />
                <h3>Tester Users</h3>
                <p>
                  Tester users have the ability to enter test reports.
                  Certification information and associated documents are
                  required. Test kit information is optional.
                </p>
              </div>
            </div>
          </div>

          <div className="section-grey">
            <div className="group-faq">
              <div className="module">
                <FontAwesomeIcon icon={faCircleQuestion} className="icon" />
                <h3>Frequently Asked Questions</h3>
                <details>
                  <summary>What is SwiftComply Backflow?</summary>
                  <p>
                    We work with cities and water authorities across the globe
                    that are looking to modernize their operations and future
                    proof of their compliance programs against the changing
                    regulatory landscape.
                  </p>
                </details>
                <br />
                <details>
                  <summary>Why fill this form out?</summary>
                  <p>
                    To have updated information with the city and it is
                    necessary in order to submit a test through the new program.
                  </p>
                </details>
              </div>
            </div>
          </div>

          <div className="section-blue">
            <div className="group-buttons">
              <h3>Ready to register?</h3>
              <button
                className="button white"
                onClick={() => setShowOrgHome(false)}
              >
                Get Started{" "}
                <FontAwesomeIcon icon={faArrowRight} className="icon" />
              </button>
            </div>
          </div>
        </div>
      )}

      {orgInfoErrorCode && (
        <div className="org-home">
          <div className="section-grey">
            <div className="group-faq">
              <div className="module">
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="icon"
                />
                <h3>Uh Oh!</h3>
                <p>
                  You've reached the SwiftComply tester registration website.{" "}
                  {errorMessage}
                </p>
                <p>
                  <a href="https://www.swiftcomply.com/customer-support/">
                    SwiftComply Support
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrgHome;
