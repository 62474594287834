import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const Tooltip = ({ text }) => {
    const iconRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [isPastCenter, setIsPastCenter] = useState(false);


    useEffect(() => {
        const handleResize = () => {
            if (iconRef.current) {
                const rect = iconRef.current.getBoundingClientRect();
                const iconCenterX = rect.left + rect.width / 2;
                const viewportCenterX = window.innerWidth / 2;

                setIsPastCenter(iconCenterX > viewportCenterX);
            }
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [])


    return (<div className="tooltip" onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
        {visible && (<div className={`text ${isPastCenter ? "left" : "right"}`}>{text}</div>)}
        <FontAwesomeIcon icon={faCircleInfo} className="icon" ref={iconRef} />
    </div>)
}

export default Tooltip;