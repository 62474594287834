import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { getAllCertifications } from "../../utils/requests";
import { submitOrgCreate } from "../../utils/submissions";
import Tooltip from "../tooltip";
import { useToken } from "../../TokenContext";
import { preValidateCreateOrg } from "../../utils/preValidations";


const CreateOrg = ({ setShowDialog }) => {
    const { currentToken } = useToken()
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const [orgName, setOrgName] = useState("");
    const [swiftComplyEmail, setSwiftComplyEmail] = useState("");
    const [emailList, setEmailList] = useState([""]);
    const [emailFrequency, setEmailFrequency] = useState("");
    const [activeDate, setActiveDate] = useState("2034-12-31");
    const [numberOfCerts, setNumberOfCerts] = useState(1);
    const [banner, setBanner] = useState(
      "Emails must be unique for both admin and tester users. If you have already submitted this form for another organization, you must submit it again for -b CITY, ST. -b\n\n" +
        "-bAdmin Users -b\n" +
        "Admin users are for service provider administration, and do not have the ability to enter test reports. No certification information is required.\n\n" +
        "-bTester Users -b\n" +
        "Tester users have the ability to enter test reports. Certification information and associated documents are required. Test kit information is optional."
    );
    const [homePageBanner, setHomePageBanner] = useState("");
    const [internalNotes, setInternalNotes] = useState("");
    const [allCertsArray, setAllCertsArray] = useState([]);
    const [associatedAgencies, setAssociatedAgencies] = useState(new Set());

    const navigate = useNavigate()

    const addEmail = () => {
        const newEmailList = _.cloneDeep(emailList);
        newEmailList.push("");
        setEmailList(newEmailList);
    };

    const removeEmail = (idx) => {
        const newEmailList = _.cloneDeep(emailList);
        newEmailList.splice(idx, 1)
        setEmailList(newEmailList);
    };

    const handleEmailEntry = (idx, e) => {
        const newEmailList = _.cloneDeep(emailList);
        newEmailList[idx] = e.target.value;
        setEmailList(newEmailList);
    };

    const handleAssociateAgency = (agencyId) => {
        const newAssociatedAgencies = _.cloneDeep(associatedAgencies);
        newAssociatedAgencies.has(agencyId) ? newAssociatedAgencies.delete(agencyId) : newAssociatedAgencies.add(agencyId);
        setAssociatedAgencies(newAssociatedAgencies);
    }



    async function handleSubmitOrg(e) {
        e.preventDefault();
        setLoading(true);
        setErrors([]);


        const associatedAgenciesList = [];
        for (const item of associatedAgencies) associatedAgenciesList.push(item);

        const data = {
            orgURL: orgName.trim(),
            orgEndDate: activeDate,
            swiftcomplyEmail: swiftComplyEmail,
            orgEmails: emailList.map(email => ({ "email": email })),
            orgEmailFreq: emailFrequency,
            orgBanner: banner,
            numOfCerts: numberOfCerts,
            orgHomePageBanner: homePageBanner,
            orgInternalNotes: internalNotes,
            associatedAgencies: associatedAgenciesList,
        };

        const preValErrors = preValidateCreateOrg(data);
        if (preValErrors.length > 0) {
            setErrors(preValErrors);
            setLoading(false);
            document.getElementsByClassName("page")[0].scrollTo(0, 0);
            return;
        }

        const res = await submitOrgCreate(data, currentToken);

        if (res.code === "SUCCESS") {
            setShowDialog("created")
            navigate(`/scadmin/vieworedit/${encodeURIComponent(orgName)}`);
        } else {
            res.message ? setErrors([res.message]) : setErrors(["API submission error."]);
            document.getElementsByClassName("page")[0].scrollTo(0, 0);
        }
        setLoading(false);
    }



    useEffect(() => {
        const requestInfo = async () => {
            const getCertsRes = await getAllCertifications(currentToken);
            if (getCertsRes.code === "SUCCESS") {
                setAllCertsArray(Object.entries(getCertsRes.message).map((pair) => ({ agencyId: pair[0], agencyName: pair[1].agency })).sort((a, b) => {
                    if (a.agencyName.toLowerCase() < b.agencyName.toLowerCase()) {
                        return -1;
                    }
                    if (a.agencyName.toLowerCase() > b.agencyName.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }))

                // Check the default certs automatically. If we know for certain the ID of these agencies,
                // we can do this without looping, but otherwise this will ensure we are selecting the right ones.
                for (const [agencyId, certInfo] of Object.entries(getCertsRes.message)) {
                    if (certInfo.agency === "AWWA" || certInfo.agency === "ABPA" || certInfo.agency === "ASSE") {
                        setAssociatedAgencies((prevAssociatedAgencies) => {
                            const newAssociatedAgencies = new Set(prevAssociatedAgencies);
                            newAssociatedAgencies.add(agencyId);
                            return newAssociatedAgencies;
                        });
                    }
                }
            } else {
                setErrors(["API request error."]);
            }
        }

        requestInfo();
    }, [])

    return (
      <form method="post" className="form">
        <div className="section">
          <div className="sub-heading">Add Organization</div>
          <div className="group">
            {errors.length > 0 && (
              <div className="row">
                <div className="field">
                  <ul className="errors">
                    {errors.map((error, errorIdx) => (
                      <li key={errorIdx}>{error}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            <div className="row">
              <label className="field">
                <div className="label">
                  Organization Name*
                  <Tooltip
                    text={
                      "The name of the city/organization. This name will show on the tester facing form, and cannot be changed after creation."
                    }
                  />
                </div>
                <input
                  required={true}
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />
              </label>
            </div>

            <div className="row list-email">
              <label className="field">
                <div className="label">
                  SwiftComply Email
                  <Tooltip
                    text={
                      "The primary internal email to receive sign up notifications."
                    }
                  />
                </div>
                <input
                  value={swiftComplyEmail}
                  onChange={(e) => setSwiftComplyEmail(e.target.value)}
                  className="item"
                />
              </label>
            </div>

            <div className="row list-email">
              {emailList.map((email, idx) => (
                <label className="field" key={idx}>
                  <div className="label">
                    Email #{idx + 1}
                    <Tooltip
                      text={
                        "An additional email (can be internal or external) to receive sign up notifications."
                      }
                    />
                  </div>
                  <div>
                    <input
                      name="email"
                      value={email}
                      onChange={(e) => handleEmailEntry(idx, e)}
                      className="item"
                    />
                    {idx > 0 && (
                      <button
                        type="button"
                        className="button"
                        onClick={() => removeEmail(idx)}
                      >
                        Remove This Email
                      </button>
                    )}
                  </div>
                </label>
              ))}
              {emailList.length < 3 && (
                <button type="button" className="button" onClick={addEmail}>
                  Add Another Email
                </button>
              )}
            </div>

            <div className="row">
              <label className="field">
                <div className="label">
                  Email Report Frequency
                  <Tooltip
                    text={
                      "How often sign up notifications should be sent out to the emails above. \
                      Daily emails will be sent Monday - Friday at 8am EST. \
                      Weekly emails will be sent once a week on Monday at 8am EST. \
                      Twice a week emails will be sent on Monday and Thursday at 8am EST. \
                      Monthly emails will be sent every 1st of the Month at 8am EST."
                    }
                  />
                </div>
                <select
                  onChange={(e) => setEmailFrequency(e.target.value)}
                  value={emailFrequency}
                >
                  <option value={""}>Select a frequency</option>
                  <option>Daily (Business Days)</option>
                  <option>Weekly</option>
                  <option>Twice a Week (MTh)</option>
                  <option>Monthly</option>
                </select>
              </label>

              <label className="field">
                <div className="label">
                  Allowed Access Until*
                  <Tooltip
                    text={"The expiration date for public access to this form."}
                  />
                </div>
                <input
                  type="date"
                  required={true}
                  value={activeDate}
                  onChange={(e) => setActiveDate(e.target.value)}
                />
              </label>

              <label className="field">
                <div className="label">
                  Required Certifications*
                  <Tooltip
                    text={
                      "The number of certifications a tester is REQUIRED to submit. For example, if this organization accepts AWWA, ABPA, and ASSE certifications, but a tester only needs one of those to test, enter 1 here."
                    }
                  />
                </div>
                <input
                  type="number"
                  required={true}
                  value={numberOfCerts}
                  onChange={(e) => setNumberOfCerts(e.target.value)}
                  min={0}
                />
              </label>
            </div>

            <div className="table-container-row">
              <table cellPadding="0" cellSpacing="0">
                <thead>
                  <tr>
                    <th colSpan="2">
                      <div className="label">
                        Enabled Certification Agencies
                        <Tooltip
                          text={
                            "Check each certification agency that this organization recognizes. ABPA, ASSE, and AWWA are checked by default."
                          }
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allCertsArray &&
                    allCertsArray.map(({ agencyId, agencyName }) => {
                      return (
                        <tr key={agencyId}>
                          <td style={{ paddingRight: "0" }}>
                            <input
                              type="checkbox"
                              checked={associatedAgencies.has(agencyId)}
                              onChange={() => handleAssociateAgency(agencyId)}
                            />
                          </td>

                          <td>{agencyName}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="row">
              <label className="field">
                <div className="label">
                  Home Banner Message
                  <Tooltip
                    text={
                      "Additional details that will be displayed in the Registration Info section of this organization's home page."
                    }
                  />
                </div>
                <textarea
                  value={homePageBanner}
                  onChange={(e) => setHomePageBanner(e.target.value)}
                />
              </label>
            </div>

            <div className="row">
              <label className="field">
                <div className="label">
                  Form Banner Message
                  <Tooltip
                    text={
                      "Additional details that will be displayed on the first page of this organization's registration form."
                    }
                  />
                </div>
                <textarea
                  value={banner}
                  onChange={(e) => setBanner(e.target.value)}
                //   placeholder="
                //     Emails must be unique for both admin and tester users. If you have already submitted this form for another organization, you must submit it again for CITY, ST.

                //     Admin Users
                //     Admin users are for service provider administration, and do not have the ability to enter test reports. No certification information is required.

                //     Tester Users
                //     Tester users have the ability to enter test reports. Certification information and associated documents are required. Test kit information is optional.
                // "
                />
              </label>
            </div>

            <div className="row">
              <label className="field">
                <div className="label">
                  Internal Notes
                  <Tooltip
                    text={
                      "Additional details for internal reference that will be NOT be publicly displayed."
                    }
                  />
                </div>
                <textarea
                  value={internalNotes}
                  onChange={(e) => setInternalNotes(e.target.value)}
                />
              </label>
            </div>

            <div className="row-buttons">
              <button
                type="submit"
                className="button green"
                onClick={(e) => handleSubmitOrg(e)}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
}

export default CreateOrg;
