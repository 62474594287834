import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
// import './sidebar.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faUser } from '@fortawesome/free-solid-svg-icons'
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons";


const sideBarItems = [
    {
        "label": 'Home',
        "url": '/scadmin',
        "section": 'home',
        "icon": <FontAwesomeIcon icon={faHouse} />
    },
    {
        "label": 'Create Organization',
        "url": '/scadmin/create',
        "section": 'create',
        "icon": <FontAwesomeIcon icon={faPlus} />
    },
    {
        "label": 'Search/Edit Organization',
        "url": '/scadmin/search',
        "section": 'edit',
        "icon": <FontAwesomeIcon icon={faPenToSquare} />
    },
    {
        "label": 'Certifications',
        "url": '/scadmin/certifications',
        "section": 'toggle',
        "icon": <FontAwesomeIcon icon={faToggleOn} />
    },
    // {
    //     "label": 'Search Organizations',
    //     "url": '/scadmin/getorgurl',
    //     "section": 'search',
    //     "icon": <FontAwesomeIcon icon={faMagnifyingGlass} />
    // },
    {
        "label": 'Active Organizations',
        "url": '/scadmin/active',
        "section": 'active',
        "icon": <FontAwesomeIcon icon={faCheck} />
    },
    {
        "label": 'In-Active Organizations',
        "url": '/scadmin/inactive',
        "section": 'inactive',
        "icon": <FontAwesomeIcon icon={faXmark} />
    },
    {
        "label": 'Reports',
        "url": '/scadmin/reports',
        "section": 'reports',
        "icon": <FontAwesomeIcon icon={faFileCsv} />
    },
    // {
    //   "label": 'Users',
    //   "url": '/scadmin/users?page=1',
    //   "section": 'users',
    //   "icon": <FontAwesomeIcon icon={faUser} />
    // }
]

export default function SideBar({ setShowDialog }) {
    const [activeIdx, setActiveIdx] = useState(0)
    const location = useLocation()

    useEffect(() => {
        // Not working currently, needs to be refactored.
        const path = window.location.pathname.split('/')[1]
        const activeLabel = sideBarItems.findIndex(item => item.section === path)
        setActiveIdx(path.length === 0 ? 0 : activeLabel)
    }, [location])

    return (
      <div className="sidebar">
        <img
          src="../../InfinityIcon2.svg"
          alt="form"
          className="sidebar-icon"
        />

        {sideBarItems.map((item, idx) => (
          <Link
            to={item.url}
            key={idx}
            className={activeIdx === idx ? "link active" : "link"}
            onClick={() => setShowDialog("")}
          >
            <div>
              <div className="icon">{item.icon}</div>
              <div className="label">{item.label}</div>
            </div>
          </Link>
        ))}
      </div>
    );
}
