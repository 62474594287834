import React from "react";
import { getErrorMessages } from "../utils/errors";
import Tooltip from "./tooltip";

const Admin = ({ adminInfo, handleAdminEntry, removeAdmin, adminErrors }) => {
    const errorMessages = getErrorMessages(adminErrors);

    return (
        <>
            {adminInfo.map((admin, idx) => {
                return (
                    <div className="section" key={idx}>
                        <div className="sub-heading">Admin #{idx + 1}</div>
                        <div className="group">

                            {errorMessages[idx]?.length > 0 && (
                                <div className="row">
                                    <div className="field">
                                        <ul className="errors">
                                            {errorMessages[idx].map((error, errorIdx) => <li key={errorIdx}>{error}</li>)}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <label className={adminErrors[idx]?.userFirstName ? "field error-highlight" : "field"}>
                                    First Name*
                                    <input
                                        name="userFirstName"
                                        value={admin.userFirstName}
                                        onChange={(event) => handleAdminEntry(idx, event)}
                                    />
                                </label>
                                <label className={adminErrors[idx]?.userLastName ? "field error-highlight" : "field"}>
                                    Last Name*
                                    <input
                                        name="userLastName"
                                        value={admin.userLastName}
                                        onChange={(event) => handleAdminEntry(idx, event)}
                                    />
                                </label>
                            </div>

                            <div className="row">
                                <label className={adminErrors[idx]?.userEmail ? "field error-highlight" : "field"}>
                                    <div className="label">
                                        Email*
                                        <Tooltip text={"This must be a unique, individual email address. A unique email address is required for each tester or admin being registered."} />
                                    </div>
                                    <input
                                        name="userEmail"
                                        value={admin.userEmail}
                                        onChange={(event) => handleAdminEntry(idx, event)}
                                    />
                                </label>
                                <label className={adminErrors[idx]?.userPhone ? "field error-highlight" : "field"}>
                                    Phone
                                    <input
                                        name="userPhone"
                                        value={admin.userPhone}
                                        onChange={(event) => handleAdminEntry(idx, event)}
                                    />
                                </label>
                            </div>

                            <div className="row">
                                {idx > 0 && (
                                    <button type="button" onClick={() => removeAdmin(idx)} className="button">Remove This Admin</button>
                                )}
                            </div>

                        </div>
                    </div>
                );
            })}
        </>
    )
}


export default Admin;