import React, { useState } from 'react';
import './SCAdmin_Auth.css'
import LoginForm from './LoginForm'
import infinityWhite from './infinitylogowhite.svg';
import fullLogo from './SwiftComplyRetina.png';

const LoginContainer = ({ onLoginSuccess }) => {
  return (
    <>
    <div className="loginContainer">
			<div className="loginLeft">
				<img className="loginLeft__logo" src={infinityWhite} />
			</div>
			<div className="loginRight">
				<div className="loginRight__container">
					<div className="loginRight__logo-wrapper">
						<img className="loginRight__logo" src={fullLogo} />
					</div>
							<>
								{<LoginForm onLoginSuccess={onLoginSuccess}/>}
							</>
				</div>
			</div>
		</div>
    </>
  );
};

export default LoginContainer;
