export const countEmails = (adminInfo, testerInfo) => {
    // Combine all the unique emails from admins and
    // testers into a set and return that.

    const emailCounts = {};

    for (const admin of adminInfo) {
        const adminEmail = admin.userEmail.toLowerCase();
        if (adminEmail) {
            emailCounts[adminEmail] ? emailCounts[adminEmail] += 1 : emailCounts[adminEmail] = 1;
        }
    }

    for (const tester of testerInfo) {
        const testerEmail = tester.userEmail.toLowerCase();
        if (testerEmail) {
            emailCounts[testerEmail] ? emailCounts[testerEmail] += 1 : emailCounts[testerEmail] = 1;
        }
    }

    return emailCounts;
}


export const uniqueEmail = (email, emailCounts) => {
    // Return true if the given email is unique when compared
    // to all other entered emails for admins and testers,
    // else return false.

    if (emailCounts[email.toLowerCase()] > 1) {
        return false;
    } else {
        return true;
    }
}