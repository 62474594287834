import React from "react";

const FetchFailMessage = () => {
    return (
        <div className="form">
            <div className="section">
                <div className="group">
                    <p className="first-p">
                        There was a problem reaching our servers. Please try again later.
                    </p>

                    <p className="last-p">
                        <a href="https://www.swiftcomply.com/customer-support/">SwiftComply Support</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FetchFailMessage;